import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { convertDate, emptyCart, updateMetaTags, emailReceipt, validateForm } from '../../actions/';
import { db } from '../../actions/settings';
import { PaymentDetails } from './../checkout/paymentDetails';
import { AddressText } from './../checkout/addressText';
import { ToastsStore } from 'react-toasts';

class AccountOrderDetailsComponent extends React.Component {

    state = {
        orderNumber: this.props.match.params.orderid,
        order: {},
        updated: false,
        email: '',
        sendingEmail: false
    }

    componentDidMount = async () => {
        let updated = new URLSearchParams(this.props.location.search).get("updated");
        this.props.updateMetaTags({
            title: 'My Orders - The British Tunnelling Society',
            description: "My Orders with the British Tunnelling Society.",
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: 'My Orders, British Tunnelling Society, BTS, Tunnelling, Tunnels, Mining, TBM'
                }
            }
        });

        try {
            let doc = await db   
                .collection("Orders")
                .doc(this.state.orderNumber)
                .get();
            if (doc.exists && this.props.user.info.id === doc.data().customerId) {
                let order = doc.data();
                this.setState({
                    order,
                    date: convertDate(doc.data().createdDate.toDate()),
                    updated
                });
            } else {
                this.props.history.push("/account/orders");
            }
        } catch(e) {
            console.log(e);
            this.props.history.push("/account/orders");
        }
    }

    sendEmail = async (e) => {
        console.log("LOL")
        let email = e.toString();
        let error = validateForm([['email', email]]);
        if (!email.includes("@")) {
            error = true;
            window.$('#email').addClass('is-invalid');
        }
        if (!error) {
            window.$('#email').removeClass('is-invalid');
            this.setState({sendingEmail: true}, async() => {
                await emailReceipt(this.state.order.payment.receipt_url, email)
                this.setState({sendingEmail: false, email: ''});
                ToastsStore.success("Receipt sent.");
            });
        }
    }

    render() {
        let updated = this.state.updated;
        return (
            <>
                <div className="page-title page-title-style-01 bkg-img09">
                    <div className="pt-mask-light"></div>
                </div>
                <div className="page-content">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                {updated &&
                                <div className="message-boxes successbox-default">
                                    <div className="icon-container">
                                        <i className="fa fa-check"></i>
                                    </div>
                                    <div className="notification-container" style={{paddingLeft:100+'px'}}>
                                        <p>Your order has been updated.</p>
                                    </div>
                                </div>
                                }
                                {!updated && 
                                <div className="message-boxes successbox-default">
                                    <div className="icon-container">
                                        <i className="fa fa-check"></i>
                                    </div>
                                    <div className="notification-container" style={{paddingLeft:100+'px'}}>
                                        <p>Checkout was completed on {this.state.date}</p>
                                    </div>
                                </div>
                                }
                                {!this.state.order.paid &&
                                <div className="message-boxes infobox-default" style={{marginBottom:50+'px'}}>
                                    <div className="icon-container">
                                        <i className="fa fa-info"></i>
                                    </div>
                                    <div className="notification-container" style={{paddingLeft:100+'px'}}>
                                        <p>You can still <Link to={`/account/orders/${this.state.orderNumber}/edit`}>make changes</Link> to your order.</p>
                                    </div>
                                </div>
                                }
                                <div className="row">
                                    <div className="col-md-4">
                                        <h4>Order Details</h4>
                                        <p><b>Delivery Address (if applicable)</b></p>
                                        <AddressText address={this.state.order.delivery}/>
                                        <p><b>Billing Address</b></p>
                                        <AddressText address={this.state.order.billing}/>
                                    </div>
                                    <div className="col-md-8">
                                        <PaymentDetails order={this.state.order} parent={this}/>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="table-responsive">
                                                    <table className="table events-table">
                                                        <thead>
                                                            <tr>
                                                                <th>Product</th>
                                                                <th>Price</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.order.items && 
                                                            <>
                                                            {this.state.order.items.map((product, i) => {
                                                                return <tr key={i}>
                                                                    <td>{product.title} x{product.quantity}</td>
                                                                    <td>£{(product.priceTotal*product.quantity).toFixed(2)}</td>                                            
                                                                </tr>
                                                            })}   
                                                            </>
                                                            }
                                                            <tr>
                                                                <td>Total:</td>
                                                                <td>£{this.state.order.total}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = state => {
    return state;
}

const mapDispatchToProps = {
    emptyCart,
    updateMetaTags
}
  
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(AccountOrderDetailsComponent));