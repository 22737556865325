import React from 'react';
import { connect } from 'react-redux';
import ResetPasswordComponent from './../../components/account/reset';

export let ResetPassword = (props) => {
    return <ResetPasswordComponent parent={props}/>
}

const mapStateToProps = state => {
    return state;
}
  
export default connect(
    mapStateToProps
)(ResetPassword);