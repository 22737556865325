import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { db } from '../../actions/settings';
import { setCurrentTab } from './../../actions/index';

class Navlist extends React.Component {
  state = {
    tabs: [],
    currentTab: '',
    loading: true
  };

  componentDidMount = async () => {
    let tabDocs;
    if (!this.props.storage.navigation) {
      tabDocs = await db
        .collection('Tabs')
        .where('active', '==', true)
        .where('deleted', '==', false)
        .orderBy('displayOrder')
        .get();
      if (tabDocs.size > 0) {
        this.getTabs(tabDocs).then(tabs => {
          this.setTabIfEmpty(tabs);
          this.getCurrentTab(tabs);
          this.orderTabs(tabs);
          this.setState({loading: false});
        });
      }
    } else {
      if (this.props.storage.navigation.length > 0) {
        let tabs = [];
        this.props.storage.navigation.map((tab) => {
          let t = tab;
          t.parentTab = (tab.parentTab) ? (tab.parentTab.id) ? tab.parentTab.id : tab.parentTab : '';
          tabs.push(t);
        });
        this.setTabIfEmpty(tabs);
        this.getCurrentTab(tabs);
        this.orderTabs(tabs);
        this.setState({loading: false});
      }
    }
    
    window
      .$('#loading-status')
      .delay(250)
      .fadeOut();
    window
      .$('#loader')
      .delay(250)
      .fadeOut('slow');
    setTimeout(function() {
      window.VolcannoInclude.triggerAnimation();
    }, 100);
  };

  getTabs = tabDocs => {
    return new Promise(resolve => {
      let tabs = [];
      tabDocs.forEach(doc => {
        let data = doc.data();
        tabs.push({
          title: data.title,
          link: '',
          id: data.id,
          parentTab: data.parentTab.id,
          page: data.page.id,
          link: data.link
        });
      });
      resolve(tabs);
    });
  };

  makeFullLink = (title, pageTitle) => {
    let folder = title.split(' ').join('-');
    let page = pageTitle.split(' ').join('-');
    let subpage = (this.props.match.params.subpage) 
      ? this.props.match.params.subpage.split(' ').join('-').toLowerCase() 
      : '';
    return "/" + folder.toLowerCase() + '/' + page.toLowerCase() + subpage;
  };

  setTabIfEmpty = (tabs) => {
    // set current tab if it's empty
    if (!this.props.nav.currentTab.id) {
      let link = this.props.match.params.page;
      tabs.map(tab => {
        tabs.map(tabTwo => {
          if (tab.link === this.makeFullLink(tabTwo.title, link)) {
            this.props.setCurrentTab(tab, {}, {});
          }
        });
      });
    }
  }

  getCurrentTab = tabs => {
    this.setState({
      currentTab: this.props.nav.currentBotTab 
        || this.props.nav.currentSubTab 
        || this.props.nav.currentTab
    }, () => {
      if (this.state.currentTab.level === 1) {
        this.props.setCurrentTab(this.state.currentTab, {}, {});
      }
    });
  };

  orderTabs = tabs => {
    let tabOrder = [];
    tabs.map((tab) => {
      if (tab.level === 1) {
        let levelTwo = tabs.filter(subTab => tab.id === subTab.parentTab);
        tab.levelTwo = levelTwo;
        levelTwo.map((subTab, s) => { 
          let levelThree = tabs.filter(botTab => botTab.parentTab === subTab.id)
          tab.levelTwo[s].levelThree = levelThree;
        });
        tabOrder.push(tab);
      }
    });

    this.setState({
      tabs: tabOrder
    });
  };

  makeLink = title => {
    let folder = title.split(' ').join('-');
    return folder.toLowerCase();
  };

  getTabLevel = (tabs, checkTab) => {
    if (checkTab.parentTab.id === "") return 1;
    let parentId = checkTab.parentTab;
    let isLevelTwo = tabs.find(tab => tab.id === parentId && tab.parentTab.id == '');
    if (isLevelTwo) return 2;
    let isLevelThree = tabs.find(tab => tab.id === parentId && tab.parentTab != '');
    if (isLevelThree) return 3;
  }

  loadNav = (link, tab, subTab, botTab) => {
    this.props.setCurrentTab(tab, subTab, botTab);
    this.props.history.push(link);

  }

  displayTabs = () => {
    let display = [];
    this.state.tabs.map(tab => {
      let clss;
      if (tab.level === 1) {
        if (this.props.nav.currentTab.id === tab.id) {
          clss = "menu-item current-menu-item";
          display.push(
            <li 
              style={{cursor: 'pointer'}} 
              key={tab.title} 
              className={clss}
              onClick={() => this.loadNav(tab.link, tab, {}, {})}
            >
              <span>{tab.title}</span>
            </li>
          )
          tab.levelTwo.map((subTab) => {
            let clr = (this.props.nav.currentSubTab.id === subTab.id) ? '#dd8888' : '';
            display.push(
              <li 
                style={{cursor: 'pointer'}} 
                key={subTab.title+"_subtab"} 
                className="sub-menu-item"
                onClick={() => this.loadNav(subTab.link, tab, subTab, {})}
              >
                <span style={{color: clr}}>{subTab.title}</span>
              </li>
            )
            if (this.props.nav.currentSubTab.id === subTab.id) {
              subTab.levelThree.map((botTab) => {
                let subClr = (this.props.nav.currentBotTab.id === botTab.id) ? 'green' : '';
                display.push(
                  <li 
                    style={{cursor: 'pointer'}} 
                    key={botTab.title+"_bottab"} 
                    className="menu-level-three"
                    onClick={() => this.loadNav(botTab.link, tab, subTab, botTab)}
                  >
                    <span style={{color: subClr}}>{botTab.title}</span>
                  </li>
                )
              });
            }
          });
        } /*else {
          clss ="menu-item";
          display.push(
            <li 
              style={{cursor: 'pointer'}} 
              key={tab.title} 
              className={clss}
              onClick={() => this.loadNav(tab.link, tab, {}, {})}
            >
              <span>{tab.title}</span>
            </li>
          )
        }*/
      }
    });
    return display;
  }

  render() {
    let displayTabs = this.displayTabs();
    return (
      <div className="menu-quick-links-container">
        <ul id="menu-quick-links" className="menu">
          {displayTabs}
          {this.state.tabs.length === 0 && !this.state.loading &&
            <li className="menu-item"><Link to="/">Home</Link></li>
          }
        </ul>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return state;
};

const mapDispatchToProps = {
  setCurrentTab
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Navlist));
