import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { logout, setTabs, convertDate, setCurrentTab, getCartValues, resetMetaTags } from './../actions/';
import { db } from './../actions/settings';
import MainNavigation from './../components/navigation/main';
import DocumentMeta from 'react-document-meta';

class MainContainer extends React.Component {
  state = {
    tabs: [],
    settings: {
      phone: '',
      email: ''
    },
    latest: [],
    searchResults: [],
    search: ''
  };

  latestNews = async () => {
    let docs = await db
      .collection('Pages')
      .where('deleted', '==', false)
      .where('active', '==', true)
      .limit(4)
      .orderBy('createdDate', 'desc')
      .get();
    if (docs.size > 0) {
      let latest = [];
      docs.forEach(doc => {
        latest.push(
          <li key={doc.data().id} className="post-container">
            <div className="post-body">
              <span className="date">
                {convertDate(doc.data().createdDate.toDate())}
              </span>
              <Link to={`/pages/${doc.data().link}`}>
                <h4>{doc.data().title}</h4>
              </Link>
            </div>
          </li>
        );
      });
      this.setState({ latest });
    }
  };

  makeLink = title => {
    let folder = title.split(' ').join('-');
    return folder.toLowerCase();
  };

  componentDidMount = async () => {
    this.props.resetMetaTags();
    this.latestNews();
    let settings = await db
      .collection('Settings')
      .doc('Main')
      .get();
    if (settings.exists) {
      this.setState({
        settings: settings.data()
      });
    }
    this.setState({
      tabs: this.props.storage.navigation
    });
    window
      .$('#loading-status')
      .delay(250)
      .fadeOut();
    window
      .$('#loader')
      .delay(250)
      .fadeOut('slow');
    setTimeout(function() {
      window.VolcannoInclude.triggerAnimation();
    }, 100);
  };

  getTabs = tabDocs => {
    return new Promise(resolve => {
      let tabs = [],
        x = 1;
      tabDocs.forEach(doc => {
        let data = doc.data();
        db.collection('Pages')
          .doc(data.page.id)
          .get()
          .then(page => {
            if (page.exists) {
              tabs.push({
                title: data.title,
                link: this.makeLink(data.title) + '/' + page.data().link,
                id: data.id,
                parentTab: data.parentTab.id,
                page: data.page.id
              });
              x++;
            }
            if (x > tabDocs.size) {
              resolve(tabs);
            }
          })
          .catch(err => resolve([]));
      });
    });
  };

  navClass = current => {
    let path = this.props.location.pathname;
    let active = current === path ? 'kt-menu__item--active' : '';
    return active;
  };

  searchPages = (e) => {
    e.preventDefault();
    this.props.history.push("/search?q=" + this.state.search)
  }

  
  
  hideSearches = () => {
    window.$("#m_search").slideUp(200);
    window.$(".search-results").slideUp(200);
    window.$("#m_search").val('');
    this.setState({searchResults: []});
  }

  render() {
    let tabs = this.props.storage.navigation;
    let subNavs = this.props.storage.subNavs;
    let { total, count } = getCartValues(this.props.cart.products);
    let isTTS = (this.props.location.pathname.includes("/tts/"));
    let isBTSYM = (this.props.location.pathname.includes("/btsym/"));
    return (
      <DocumentMeta {...this.props.meta.tags}>
        <div className="header-wrapper header-style-02 header-negative-bottom clearfix">
          <header id="header" className="">
            <div className="container" id="logo-container">
              <div className="row">
                <div className="col-md-12">
                  <div className="top-bar-wrapper clearfix">
                    <div className="row">
                      <div className="col-md-4">
                        <div id="logo">
                          {!isBTSYM && 
                          <Link to="/">
                            <img
                              src="/img/logo.gif"
                              alt="The British Tunnelling Society"
                            />
                          </Link>
                          }
                          {isTTS && 
                          <div style={{marginLeft: '20px', display: 'inline-block'}}>
                            <img
                              src="/img/tts.png"
                              alt="TRANSFORMING TUNNEL SAFETY"
                            />
                          </div>
                          }
                          {isBTSYM && 
                          <div style={{display: 'inline-block'}}>
                            <img
                              src="/img/btsym.gif"
                              alt="The British Tunnelling Society Young Members"
                            />
                          </div>
                          }
                        </div>
                      </div>
                      <div className="col-md-8">
                        <div className="header-info-widgets hidden-xs hidden-sm">
                          <ul className="clearfix">
                            <li>
                              <Link to="/basket/">
                                <div className="icon-container">
                                  <i className="lynny-shopping-bag"></i>
                                </div>
                                <div className="text-container">
                                  <span>Your Basket</span>
                                  <p style={{marginTop:-8+'px'}}>{count} items: £{total}</p>
                                </div>
                              </Link>
                            </li>
                            <li>
                              {this.props.user.info &&
                              <Link to="/account/details">
                                <div className="icon-container">
                                  <i className="lynny-user-male"></i>
                                </div>
                                <div className="text-container">
                                  <span>My Account</span>
                                </div>
                              </Link>
                              }
                              {!this.props.user.info &&
                              <Link to="/account">
                                <div className="icon-container">
                                  <i className="lynny-user-male"></i>
                                </div>
                                <div className="text-container">
                                  <span>Customer Login</span>
                                </div>
                              </Link>
                              }
                            </li>
                            <li>
                              <Link to="/contact">
                                <div className="icon-container">
                                  <i className="lynny-mail-duplicate"></i>
                                </div>

                                <div className="text-container">
                                  <span>Contact Us</span>
                                </div>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="header-inner">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <div className="main-navigation">
                      <nav className="navbar navbar-default nav-left pi-mega">
                        <div className="navbar-header">
                          <div className="header-basket">
                            <Link to="/basket/">
                              <i className="lynny-shopping-bag"></i>
                            </Link>
                          </div>
                          <div className="mobile-logo" style={{marginLeft: (isTTS || isBTSYM) ? '-50px' : '-25px'}}>
                            <Link to="/">
                              <img
                                src="/img/logo.gif"
                                style={{height:50+'px'}}
                                alt="The British Tunnelling Society"
                              />
                              
                            </Link>
                            {isTTS && 
                              <div style={{marginLeft: '20px', display: 'inline-block'}}>
                                <img
                                  src="/img/tts.png"
                                  style={{height:50+'px'}}
                                  alt="TRANSFORMING TUNNEL SAFETY"
                                />
                              </div>
                              }
                              {isBTSYM && 
                              <div style={{marginLeft: '20px', display: 'inline-block'}}>
                                <img
                                  src="/img/btsym.gif"
                                  style={{height:50+'px'}}
                                  alt="The British Tunnelling Society Young Members"
                                />
                              </div>
                              }
                          </div>
                          <button
                            type="button"
                            className="navbar-toggle collapsed"
                            data-toggle="collapse"
                            data-target="#main-nav"
                            aria-expanded="false"
                          >
                            <span className="sr-only">Toggle navigation</span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                          </button>
                        </div>
                        <MainNavigation tabs={tabs} subNavs={subNavs} history={this.props.history} setCurrentTab={this.props.setCurrentTab}/>
                        <div className="nav-additional-links">
                          <div id="search">
                            <form onSubmit={this.searchPages}>
                              <input className="hide" type="submit" />
                              <input className="search-submit" type="text" />
                              <input
                                id="m_search"
                                name="s"
                                type="text"
                                onChange={(e) => this.setState({search:e.target.value})}
                                placeholder="Type and hit enter..."
                              />
                            </form>
                          </div>
                        </div>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </header>
        </div>
        {this.props.children}
        <div id="footer-wrapper">
          <footer id="footer">
            <div className="container">
              <div className="row">
                <ul className="footer-widget-container col-md-3 col-sm-6">
                  <li className="widget widget-text">
                    <Link to="/">
                      <img
                        data-delayedsrc="/img/logo_dark.png"
                        alt="The British Tunnelling Society"
                      />
                    </Link>
                    <p>The British Tunnelling Society is a Registered Charity in England and Wales. Charity Number 1017887</p>
                  </li>
                </ul>
                <ul className="footer-widget-container col-md-3 col-sm-6">
                  <li className="widget widget-pages">
                    <div className="title">
                      <h3>More Info.</h3>
                    </div>
                    <ul>
                      <li>
                        <Link onClick={() => window.$('.scroll-up').click()} to="/">Home</Link>
                      </li>
                      <li>
                        <Link onClick={() => window.$('.scroll-up').click()} to="/pages/cookies">Cookies</Link>
                      </li>
                      <li>
                        <Link onClick={() => window.$('.scroll-up').click()} to="/pages/privacy">Privacy</Link>
                      </li>
                      <li>
                        <Link onClick={() => window.$('.scroll-up').click()} to="/pages/refunds">Refunds</Link>
                      </li>
                      <li>
                        <Link onClick={() => window.$('.scroll-up').click()} to="/pages/faq">FAQ</Link>
                      </li>
                      <li>
                        <Link onClick={() => window.$('.scroll-up').click()} to="/contact">Contact Us</Link>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul className="footer-widget-container col-md-3 col-sm-6">
                  <li className="widget widget-text">
                    <div className="title">
                      <h3>Latest News</h3>
                    </div>
                    <ul className="pi-latest-posts-02">{this.state.latest}</ul>
                  </li>
                </ul>
                <ul className="footer-widget-container col-md-3 col-sm-6">
                  <li className="widget contact-info">
                    <div className="title">
                      <h3>Contact</h3>
                    </div>

                    <ul className="contact-info-list clearfix">
                      <li>
                        <i className="lynny-paper-plane"></i>
                        <Link
                          onClick={() => window.$('.scroll-up').click()}
                          to="/contact"
                        >
                          Send us a message
                        </Link>
                      </li>

                      <li>
                        <i className="lynny-phone-1"></i>
                        {this.state.settings.phone}
                      </li>

                      <li>
                        <i className="lynny-mail-duplicate"></i>
                        <a href={`mailto:${this.state.settings.email}`}>
                          {this.state.settings.email}
                        </a>
                      </li>
                      <li>
                        <i className="lynny-map-pin-1"></i>
                        <span>
                          The British Tunnelling Society,<br/>
                          One Great George Street, <br/>
                          SW1P 3AA, <br/>London.
                        </span>
                      </li>
                    </ul>
                    
                  </li>
                </ul>
              </div>
            </div>
          </footer>
          <div id="copyright-container" className="copyright-container">
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-sm-6">
                  <p>Copyright © {(new Date).getFullYear()} The British Tunnelling Society. All Rights Reserved.</p>
                </div>
                <div className="col-md-6 col-sm-6 copyright-right">
                  <p>
                    Design and development by{' '}
                    <a target="_blank" href="https://applow.com">
                      Applow
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <a href="#" className="scroll-up">
            <i className="fa fa-chevron-up"></i>
          </a>
        </div>
      </DocumentMeta>
    );
  }
}

const mapStateToProps = state => {
  return state;
};

const mapDispatchToProps = {
  logout,
  setTabs,
  setCurrentTab,
  resetMetaTags
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MainContainer));
