import React from 'react';
import { connect } from 'react-redux';
import BasketComponent from './../../components/checkout/basket';

export let Basket = (props) => {
    return <BasketComponent parent={props}/>
}

const mapStateToProps = state => {
    return state;
}
  
export default connect(
    mapStateToProps
)(Basket);