import React from 'react';

// templates
import { StandardArticle } from './standard_article';
import { ArticleWithImage } from './article_with_image';
import {DownloadPage} from './download_page';
import MultiImagePage from './mulit_image_page';
import { FlexiblePage } from './flexi_page';

export function ContentSwitch({ template, data, showingAll }) {
  var event = new Event('changing');
  window.dispatchEvent(event);
  window.$('.scroll-up').click();
  switch (template) {
    case 'StandardArticle':
      return <StandardArticle data={data} showingAll={showingAll}/>;
    case 'ArticleWithImage':
      return <ArticleWithImage data={data} showingAll={showingAll}/>;
    case 'DownloadPage':
      return <DownloadPage data={data} showingAll={showingAll}/>;
    case 'MultiImagePage':
      return <MultiImagePage data={data} showingAll={showingAll}/>;
    case 'FlexiblePage':
      return <FlexiblePage data={data} showingAll={showingAll}/>;
    default:
      return null;
  }
};
