import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

const MainNavigation = ({ tabs, subNavs, history, setCurrentTab }) => {

  const loadNav = (link, tab, subTab, botTab) => {
    setCurrentTab(tab, subTab, botTab);
    history.push(link);
  }

  const loadStaticPage = (link) => {
    history.push(link);
  }

  return (
    <div id="main-nav" className="collapse navbar-collapse">
      <ul className="nav navbar-nav">
        <li
          style={{cursor: 'pointer'}} 
          className="current-menu-item"
          onClick={() => loadStaticPage("/")}
        >
          <a to="/">Home</a>
        </li>
        {tabs && tabs.map(tab => {
          if (tab.parentTab.id === '') {
            let link = tab.link !== '' ?  tab.link : '/';
            if (subNavs[tab.id] && subNavs[tab.id].subNavs.length > 0) {
              return <li 
                  className="menu-item-has-children dropdown" 
                  key={tab.title}
                  style={{cursor: 'pointer'}} 
                >
                  <a 
                    data-toggle="dropdown" 
                    className="dropdown-toggle" 
                    role="button"
                  >
                    {tab.title}
                  </a>
                  <ul className="dropdown-menu">
                    {subNavs[tab.id].subNavs.map((subTab) => {
                      let sublink = subTab.link !== '' ?  subTab.link : '/';
                      return <li 
                        key={subTab.id}
                        style={{cursor: 'pointer'}} 
                        onClick={() => loadNav(sublink, tab, subTab, {})}
                      >
                        <a>{subTab.title}</a>
                      </li>
                    })}
                  </ul>
              </li>
            } else {
              return (
                <li 
                  key={tab.id}
                  style={{cursor: 'pointer'}} 
                  className="current-menu-item"
                  onClick={() => loadNav(link, tab, {}, {})}
                >
                  <a>{tab.title}</a>
                </li>
              );
            }
          }
        })}
      </ul>
    </div>
  );
};

const mapStateToProps = state => {
  return state;
};

export default withRouter(connect(mapStateToProps)(MainNavigation));
