import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { db, firebaseApp, firebaseD } from '../../actions/settings';
import { loginError, login, validateForm, createStripeCustomer, setMetaData } from '../../actions/';
import ClipLoader from "react-spinners/ClipLoader";

class AccountComponent extends React.Component {

    state = {
        loading: false,
        email: '',
        password: '',
        firstname: '',
        lastname: '',
        r_email: '',
        r_password: '',
        c_password: '',
        regError: '',
        loginError: ''
    }

    componentDidMount() {
        if (this.props.user.loggedIn && this.props.user.info) { 
            this.props.history.push("/account/details");
        }
        this.props.setMetaData({
            title: 'Login or Register to BTS - The British Tunnelling Society',
            description: "Login or register to the British Tunnelling Society."
        });
    }

    handleRegister = async () => {
        this.setState({loading:true, regError: ''}, async () => {
            let { firstname, lastname, r_email, r_password, c_password } = this.state;
            let checkArr = [
                ["firstname", firstname],
                ["lastname", lastname],
                ["r_email", r_email],
                ["r_password", r_password],
                ["c_password", c_password]
            ];
            let regError = validateForm(checkArr);
            if (!regError && c_password !== r_password) {
                regError = "Your passwords don't match.";
                this.setState({regError});
            }
            if (!regError) {
                try {
                    let data = await firebaseApp
                        .auth()
                        .createUserWithEmailAndPassword(r_email, r_password);
                    if (data) {
                        let dbRef = db.collection("Customers").doc(data.user.uid);
                        let userInfo = {
                            title: '',
                            firstname: firstname,
                            lastname: lastname,
                            stripeCustomerId: '',
                            active: true,
                            id: data.user.uid,
                            email: r_email,
                            deleted: false,
                            createdBy: firstname + ' ' + lastname,
                            createdDate: firebaseD.firestore.FieldValue.serverTimestamp()
                        }
                        await dbRef.set(userInfo);
                        await createStripeCustomer(data.user.uid);
                        this.props.login(userInfo);
                        let redirectTo = (this.props.location.redirectTo) 
                            ? this.props.location.redirectTo 
                            : "/account/details";
                        this.props.history.push(redirectTo);
                    } else {
                        this.setState({regError: "Unable to complete registration", loading: false});
                    }
                } catch(e) {
                    console.log(e);
                    this.setState({regError: e.message, loading: false});
                }
            }
        });
    }

    resetLogin = () => {
        firebaseD.auth().signOut().then(() => {
            this.setState({
                loginError: 'Invalid email or password.',
                loading: false
            });
        });
    }

    handleLogin = () => {
        this.setState({loading:true, loginError: ''}, () => {
            let { email, password } = this.state;
            let checkArr = [
                ["email", email],
                ["password", password]
            ];
            let loginError = validateForm(checkArr);
            if (!loginError) {
                firebaseApp.auth().signInWithEmailAndPassword(email, password)
                .then((data) => {
                    let uid = data.user.uid;
                    db.collection("Customers")
                    .doc(uid).get()
                    .then((doc) => {
                        if (doc.exists) {
                            let userData = doc.data();
                            this.props.login(userData);
                            let redirectTo = (this.props.location.redirectTo) 
                                ? this.props.location.redirectTo 
                                : "/account/details";
                            this.props.history.push(redirectTo);
                        } else {
                            this.resetLogin();
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        this.resetLogin();
                    });
                })
                .catch(() => {
                    this.setState({
                        loginError: 'Invalid email or password.',
                        loading: false
                    });
                });
            }
        });
    }

    render() {
        return (
            <>
            <div className="page-title page-title-style-01 bkg-img09">
                <div className="pt-mask-light"></div>
            </div>
            <div className="page-content">
                <div className="container">
                    <div className="row">
                        <div className="col-md-5">
                            <div className="custom-heading-03">
                                <h3>Login</h3>
                                <p>Please enter your login details</p>
                            </div>
                            <form className="wpcf7 wpcf7-contact-us clearfix" onSubmit={this.handleLogin}>
                                <input 
                                    onChange={(e) => this.setState({email: e.target.value})} value={this.state.email} 
                                    type="text" className="wpcf7-textarea" id="email" placeholder="Email"
                                />
                                <input 
                                    onChange={(e) => this.setState({password: e.target.value})} value={this.state.password} 
                                    type="password" className="wpcf7-textarea" id="password" placeholder="Password"
                                />
                                {this.state.loginError && 
                                <div className="message-boxes errorbox-default">
                                    <div className="notification-container">
                                        <p>{this.state.loginError}</p>
                                    </div>
                                </div>
                                }
                                <Link to="/reset">Forgot password?</Link>
                                {!this.state.loading && 
                                <button onClick={this.handleLogin} type="button" value="Login" className="wpcf7-submit">Login</button>
                                }
                                {this.state.loading && 
                                <button type="button" className="wpcf7-submit">
                                    <ClipLoader
                                        size={24}
                                        color={"#ffffff"}
                                        loading={this.state.loading}
                                    />
                                </button>
                                }
                            </form>
                        </div>
                        <div className="col-md-2"></div>
                        <div className="col-md-5">
                            <div className="custom-heading-03">
                                <h3>Register</h3>
                                <p>Please enter your details</p>
                            </div>
                            <form className="wpcf7 wpcf7-contact-us clearfix" onSubmit={this.handleRegister}>
                                <input 
                                    onChange={(e) => this.setState({firstname: e.target.value})} value={this.state.firstname} 
                                    type="text" className="wpcf7-text" id="firstname" placeholder="First name"
                                />
                                <input 
                                    onChange={(e) => this.setState({lastname: e.target.value})} value={this.state.lastname} 
                                    type="text" className="wpcf7-text" id="lastname" placeholder="Last name"
                                />
                                <input 
                                    onChange={(e) => this.setState({r_email: e.target.value})} value={this.state.r_email} 
                                    type="email" className="wpcf7-textarea" id="r_email" placeholder="Email"
                                />
                                <input 
                                    onChange={(e) => this.setState({r_password: e.target.value})} value={this.state.r_password} 
                                    type="password" className="wpcf7-textarea" id="r_password" placeholder="Password"
                                />
                                <input 
                                    onChange={(e) => this.setState({c_password: e.target.value})} value={this.state.c_password} 
                                    type="password" className="wpcf7-textarea" id="c_password" placeholder="Confirm password"
                                />
                                {this.state.regError && 
                                <div className="message-boxes errorbox-default">
                                    <div className="notification-container">
                                        <p>{this.state.regError}</p>
                                    </div>
                                </div>
                                }
                                {!this.state.loading && 
                                <button onClick={this.handleRegister} type="button" value="Login" className="wpcf7-submit">Register</button>
                                }
                                {this.state.loading && 
                                <button type="button" className="wpcf7-submit">
                                    <ClipLoader
                                        size={24}
                                        color={"#ffffff"}
                                        loading={this.state.loading}
                                    />
                                </button>
                                }
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            </>
        )
    }
}

const mapStateToProps = state => {
    return state;
}

const mapDispatchToProps = {
    loginError,
    login,
    setMetaData
}
  
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(AccountComponent));