import * as firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/auth';

var config = {
    apiKey: "AIzaSyAwd07wQGt7ENbHpP7phFVsiNsAWEMEEc8",
    authDomain: "bts-org-uk.firebaseapp.com",
    databaseURL: "https://bts-org-uk.firebaseio.com",
    projectId: "bts-org-uk",
    storageBucket: "",
    messagingSenderId: "446612729790",
    appId: "1:446612729790:web:e70cbcd778f1fb600713ef"
};
  
export const firebaseApp = firebase.initializeApp(config);
export const db = firebase.firestore();
export const storage = firebase.storage();
export const firebaseD = firebase;
export const fConfig = config;