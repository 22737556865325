const defaultState = {
    loading: false,
    error: '',
    info: null,
    loggedIn: false,
}

export default function user (state = defaultState, action) {
    switch(action.type) {
        case "LOGINERROR":
            return {
                ...state,
                error: action.payload.error
            }
        case "LOGIN":
            return {
                ...state,
                info: action.payload.info,
                loggedIn: true
            }
        case "LOGOUT":
            return {
                ...state,
                loading: false,
                error: '',
                info: null,
                loggedIn: false
            }
        default:
            return state;
    }
}