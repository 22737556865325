import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { db } from '../../actions/settings';
import { ContentSwitch } from './switch';
import { ContentSkeleton } from './../../skeletons/content';
import { setCurrentTab, setMetaData } from './../../actions/';

class ContentComponent extends React.Component {
  state = {
    loading: true,
    template: '',
    page: null
  };

  componentWillReceiveProps = async (newProps) => {
    
    if (newProps.match.params.page !== this.props.match.params.page) {
      this.loadPage(newProps);
    } else if (newProps.match.params.subpage !== this.props.match.params.subpage) {
      this.loadPage(newProps);
    }
  };

  componentDidMount = () => {
    this.loadPage();
  }

  loadPage = async (prevProps) => {
    if (!prevProps 
      || (prevProps.match.params.page !== this.props.match.params.page)
      || (prevProps.match.params.subpage !== this.props.match.params.subpage)
    ) {
      if (this.props.location.pathname.startsWith("/pages")) this.props.setCurrentTab({}, {}, {});
      let { page, subpage } = this.props.match.params;
      if (prevProps) {
        page = prevProps.match.params.page;
        subpage = prevProps.match.params.subpage;
      }
      if (subpage || page) {
        let link = (subpage || page);
        let docs = await db
          .collection('Pages')
          .where('link', '==', link)
          .where('deleted', '==', false)
          .where('active', '==', true)
          .limit(1)
          .get();
        if (docs.size > 0) {
          docs.forEach(doc => {
            this.setState({
              template: doc.data().template,
              page: doc.data(),
              loading: false
            });
            this.props.setMetaData({
              title: doc.data().title + ' - The British Tunnelling Society',
              description: doc.data().shortDescription
            });
          });
        } else {
          this.props.history.push('/404');
        }
      } else {
        this.props.history.push('/404');
      }
    }
  }

  render() {
    return (
      <>
        <div className="page-title page-title-style-01 bkg-img09">
          <div className="pt-mask-light"></div>
        </div>
        <div className="page-content">
          {this.state.loading && <ContentSkeleton />}
          {!this.state.loading && 
            <ContentSwitch
              template={this.state.template}
              data={this.state.page}
            />
          }
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return state;
};

const mapDispatchToProps = {
  setCurrentTab,
  setMetaData
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ContentComponent));