import React from 'react';
import Skeleton from 'react-loading-skeleton';

export const OwlSkeleton = () => {
    return <>
        <div className="owl-item">
            <div className="featured-page-box">
                <div className="media">
                    <Skeleton height={245} width={360}/>
                </div>
                <div className="body">
                    <Skeleton/>
                </div>
            </div>
        </div>
        <div className="owl-item">
            <div className="featured-page-box">
            <div className="media">
                    <Skeleton height={245} width={360}/>
                </div>
                <div className="body">
                    <Skeleton/>
                </div>
            </div>
        </div>
        <div className="owl-item">
            <div className="featured-page-box">
            <div className="media">
                    <Skeleton height={245} width={360}/>
                </div>
                <div className="body">
                    <Skeleton/>
                </div>
            </div>
        </div>
    </>
}

export const HomeSkeleton = () => {
    return <>
        <div>
            <div className="container">
                <div className="row">
                    <div className="col-md-4 col-md-push-4">
                        <div className="custom-heading-02">
                            <h2><Skeleton/></h2>
                            <span><Skeleton/></span>
                        </div>
                    </div>
                </div>
                <div className="row mb-30">
                    <div className="col-md-4">
                        <div className="service-box service-box-03">
                            <div className="icon-container">
                                <Skeleton circle={true}/>
                            </div>
                            <div className="text-container">
                                <h3><Skeleton/></h3>
                                <p>
                                <Skeleton/>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="service-box service-box-03">
                            <div className="icon-container">
                                <Skeleton circle={true}/>
                            </div>
                            <div className="text-container">
                            <h3><Skeleton/></h3>
                                <p>
                                <Skeleton/>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="service-box service-box-03">
                            <div className="icon-container">
                                <Skeleton circle={true}/>
                            </div>
                            <div className="text-container">
                                <h3><Skeleton/></h3>
                                <p>
                                <Skeleton/>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}