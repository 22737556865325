import React from 'react';
import Skeleton from 'react-loading-skeleton';

export const ContentSkeleton = () => {
    return <>
        <div className="container">
            <div className="row">
                <div className="col-md-8">
                    <div className="row mb-80">
                        <div className="col-md-12">
                            <div className="custom-heading-01">
                                <h2><Skeleton/></h2>
                            </div>
                            <p>
                                <Skeleton/><br/>
                                <Skeleton/><br/>
                                <Skeleton/><br/>
                            </p>
                            <p>
                                <Skeleton/><br/>
                                <Skeleton/><br/>
                                <Skeleton/><br/>
                                <Skeleton/><br/>
                                <Skeleton/><br/>
                            </p>
                        </div>
                    </div>
                </div>
                <aside className="col-md-4 aside-right">
                    <ul className="aside-widgets">
                        <li className="widget widget_nav_menu clearfix">
                            <div className="menu-quick-links-container">
                                <ul id="menu-quick-links" className="menu">
                                    <li className="menu-item current-menu-item">
                                    <Skeleton/>
                                    </li>
                                    <li className="menu-item">
                                    <Skeleton/>
                                    </li>
                                    <li className="menu-item">
                                    <Skeleton/>
                                    </li>
                                    <li className="menu-item">
                                    <Skeleton/>
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </aside>
            </div>
        </div>
    </>
}