import React from 'react';
import ClipLoader from "react-spinners/ClipLoader";
import { convertDate } from './../../actions/';

export const PaymentDetails = ({order, parent}) => {
    let date = (order.paid) ? convertDate(order.paidDate.toDate()) : null;
    if (order.invoice) {
        return <div className="row">
            <div className="col-md-12">
                <h4>Payment</h4>
                {order.paid && 
                <div className="message-boxes successbox-default">
                    <div className="notification-container">
                        <span>This invoice was marked as paid on {date}.</span>
                    </div>
                </div>
                }
                <p>You have selected to pay by invoice.</p>
                <a 
                    className="btn btn-success marg-right-20"
                    href={order.invoice.invoice_pdf}
                    download
                >
                    Download Invoice
                </a>
                {!order.paid && 
                <a 
                    className="btn btn-success marg-right-20"
                    href={order.invoice.hosted_invoice_url}
                    target="_blank"
                >
                    Pay Now
                </a>
                }
            </div>
        </div>
    } else if (order.payment) {
        return <>
            <div className="row">
                <div className="col-md-12">
                    <h4>Payment</h4>
                    <p>You have successfully paid by credit/debit card. A copy of the receipt has been emailed to you.</p>
                    <a 
                        className="btn btn-success marg-right-20"
                        href={order.payment.receipt_url}
                        target="_blank"
                    >
                        View Receipt
                    </a>
                </div>
            </div>
            <div className="row">
                <form className="wpcf7 wpcf7-contact-us clearfix">
                    <div className="col-md-9">
                        <label>Email the receipt</label>
                        <input 
                            onChange={(e) => parent.setState({email: e.target.value})} 
                            value={parent.state.email} 
                            type="email" 
                            className="wpcf7-textarea" 
                            id="email"
                            placeholder="Email Address"
                        />
                        {!parent.state.sendingEmail && 
                        <button 
                            className="btn btn-success marg-right-20"
                            type="button"
                            onClick={() => parent.sendEmail(parent.state.email)}
                        >
                            Send Email
                        </button>
                        }
                        {parent.state.sendingEmail && 
                        <>
                        <span>Sending Receipt... {` `}</span>
                        <ClipLoader
                            size={16}
                            color={"#5cb85c"}
                            loading={parent.state.sendingEmail}
                        />
                        </>
                        }
                    </div>
                </form>
            </div>
        </>
    } else {
        return null;
    }
}