import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { validateForm, updateQuestionnaires } from '../../actions/';
import { db } from '../../actions/settings';
import ClipLoader from "react-spinners/ClipLoader";
import { QuestionsDisplay } from './QuestionsDisplay';

class QuestionnairesComponent extends React.Component {

    state = {
        loading: false,
        saving: false,
        questionnaire: {},
        questions: [],
        questionsAndAnswers: []
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.match.params.index !== prevProps.match.params.index) {
            this.loadQuestionnaire();
        }
    }

    loadQuestionnaire = async () => {
        console.log("Loading Questionnaire")
        if (!isNaN(this.props.match.params.index)) {
            this.setState({loading: true, questionnaire: {}}, async() => {
                let qIndex = this.props.match.params.index-1;
                let questionnaire = this.props.cart.questionnaires[qIndex];
                // get data if the questions don't exist if previously loaded
                if (questionnaire && (!questionnaire.questions || questionnaire.questions.length === 0)) {
                    let doc = await db
                        .collection("Questionnaires")
                        .doc(questionnaire.id)
                        .get();
                    if (doc.exists) {
                        this.setState({
                            loading: false,
                            questionnaire: doc.data(),
                            questions: doc.data().questions,
                            questionsAndAnswers: doc.data().questions
                        });
                    } else {
                        this.props.history.push("/404");
                    }
                } else if (questionnaire.questions) {
                    this.setState({
                        loading: false,
                        questionnaire: questionnaire,
                        questions: questionnaire.questions,
                        questionsAndAnswers: questionnaire.questions
                    });
                } else if (qIndex === this.props.cart.questionnaires.length) {
                    this.props.history.push("/basket/checkout");
                } else {
                    this.props.history.push("/404");
                }
            });
        } else {
            this.props.history.push("/404");
        }
    }

    componentDidMount = () => {
        this.loadQuestionnaire();
    }

    saveAnswer = (questionIndex, value) => {
        let current = this.state.questions;
        current[questionIndex]['value'] = value;
        this.setState({questionsAndAnswers: current});
    }

    saveForm = async () => {
        this.setState({saving: true}, async () => {
            console.log("Before saving", this.state.questionsAndAnswers)
            let chkArr = [];
            this.state.questionsAndAnswers.map((question, i) => {
                if (question.answerType !== "checkbox") {
                    if (!question.value && question.requireBeforeCheckout) {
                        chkArr.push([`question_${i}`, '']);
                    }
                    if (question.answerType === "fixed" && (question.value !== question.answerInfo)) {
                        chkArr.push([`question_${i}`, '']);
                    }
                }
            });
            let error = validateForm(chkArr);
            console.log("error", chkArr);
            if (!error) {
                let current = this.props.cart.questionnaires;
                console.log("Mid saving", current)
                current[this.props.match.params.index-1].questions = this.state.questionsAndAnswers;
                current[this.props.match.params.index-1].answered = true;
                await this.props.updateQuestionnaires(current);
                console.log("After saving", current)
                if (current.length > this.props.match.params.index) {
                    this.props.history.push(`/basket/checkout/questions/${Number(this.props.match.params.index)+1}`);
                } else {
                    this.props.history.push("/basket/checkout");
                }
            }
            this.setState({saving: false});
        });
    }

    render() {
        let questionnaire = this.state.questionnaire;
        let questions = this.state.questions;
        return (
            <>
                <div className="page-title page-title-style-01 bkg-img09">
                    <div className="pt-mask-light"></div>
                </div>
                <div className="page-content">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="custom-heading-01">
                                    <h2>Product Questionnaires</h2>
                                    <p>
                                        At least one of the items in your cart requires some more information from you. 
                                        Please answer the questions below before proceeding to checkout.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <form className="wpcf7 wpcf7-contact-us clearfix">
                                    {!this.state.loading && 
                                    <div className="accordion accordion-boxed clearfix">
                                        <div className="title active">
                                            <a href="#" className="clearfix">
                                                {questionnaire.title}
                                            </a>
                                            <p className="questionnaire-desc">{questionnaire.description}</p>
                                        </div>
                                        <div className="content clearfix">
                                            {questions.map((question, x) => {
                                                let req = (question.requireBeforeCheckout) 
                                                    ? <span className="required">*</span>
                                                    : null;
                                                return <div className="form-group row" key={x}>
                                                    <QuestionsDisplay 
                                                        question={question} 
                                                        index={x} 
                                                        saveAnswer={this.saveAnswer}
                                                        req={req}
                                                    />
                                                </div>
                                            })}
                                        </div>
                                    </div>        
                                    }  
                                    <ClipLoader
                                        size={24}
                                        color={"#071740"}
                                        loading={this.state.loading}
                                    />
                                    {!this.state.loading &&
                                    <div className="row">
                                        <div className="col-md-12">
                                            <button 
                                                className="btn btn-grey" 
                                                onClick={() => this.props.history.push("/basket")}
                                                type="button"
                                            >Back to Basket
                                            </button>
                                            {!this.state.saving && 
                                            <button 
                                                className="btn btn-success pull-right" 
                                                onClick={() => this.saveForm()}
                                                type="button"
                                            >Submit</button>
                                            }
                                            {this.state.saving && 
                                            <button 
                                                className="btn btn-success pull-right" 
                                                type="button"
                                                ><ClipLoader
                                                size={24}
                                                color={"#071740"}
                                                loading={this.state.saving}
                                                />
                                            </button> 
                                            }
                                        </div>
                                    </div>
                                    }
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = state => {
    return state;
}

const mapDispatchToProps = {
    updateQuestionnaires
}
  
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(QuestionnairesComponent));