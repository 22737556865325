import React, { useEffect } from 'react';

export const QuestionsDisplay = ({question, index, saveAnswer, req}) => {

    useEffect(() => {
        // reload
    }, [index]);

    let label = <label>{question.title} {req}</label>;
    let options = question.answerInfo.split("~");
    switch(question.answerType) {
        case "text":
            return <>
                {label}
                    <input 
                    value={question.value || ''} 
                    onChange={(e) => saveAnswer(index, e.target.value)} 
                    type="text" 
                    className="wpcf7-textarea" 
                    id={`question_${index}`}
                />
            </>
        case "dropdown":
            return <>
                {label}
                <select 
                    value={question.value || ''} 
                    onChange={(e) => saveAnswer(index, e.target.value)} 
                    className="wpcf7-textarea" 
                    id={`question_${index}`}
                >
                    <option value="">Please select</option>
                    {options.map((o,x) => (
                        <option key={x} value={o}>{o}</option>
                    ))}
                </select>
            </>
        case "checkbox":
            return <label className="chk-container">{question.title}
                <input  
                    type="checkbox"
                    checked={question.value || false}
                    onChange={(e) => saveAnswer(index, e.target.checked)}
                    value={question.value || false}
                    id={`question_${index}`}
                />
                <span className="checkmark"></span>
            </label>
        case "radio":
            return <>
                {label}
                <div className="radio-btns">
                    {options.map((o,x) => (
                        <div className="floatBlock" key={x}>
                            <label htmlFor={`question_${index}`} > 
                                <input 
                                    id={`question_${index}`} 
                                    name={`question_${index}`} 
                                    type="radio" 
                                    onChange={(e) => saveAnswer(index, e.target.value)}
                                    checked={(question.value === o) ? true : false}
                                    value={o}
                                />  
                                {o}
                            </label>
                        </div>
                    ))}
                </div>
            </>
        case "fixed":
            return <>
            {label}
                <input 
                value={question.value} 
                onChange={(e) => saveAnswer(index, e.target.value)} 
                type="text" 
                className="wpcf7-textarea" 
                id={`question_${index}`}
            />
        </>;
    }
}