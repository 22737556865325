import React from 'react';
import { Link } from 'react-router-dom';
import { convertDate, stripHtml } from './../../actions/';

export const LatestNews = ({ news }) => {
  let html = <ul className="col-md-8 blog-posts blog-list clearfix text-left" 
    style={{
        width: 100+'%'
    }}>
      {news.map((item) => {
          let cDate = item.publishDate;
          let dte = convertDate(cDate);
          let content = stripHtml(item.content);
          if (content) {
            return <li className="post-container clearfix" key={item.id}>
                <div className="post-body">
                    <span className="date">{dte.substr(0,10)}</span>
                    <Link to={`/pages/${item.link}`}>
                        <h3>{item.title}</h3>
                    </Link>
                    <p className="scroller-description">{content}</p>
                    <Link to={`/pages/${item.link}`} className="read-more">
                        Read more
                    </Link>
                </div>
            </li>
        }
      })}
    </ul>
    return html;
};
