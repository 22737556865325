import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { firebaseApp } from '../../actions/settings';
import { logout } from '../../actions/';
import ClipLoader from "react-spinners/ClipLoader";

class ResetPasswordComponent extends React.Component {

    state = {
        loading: false,
        email: '',
        resetError: ''
    }

    sendEmail = async () => {
        let { email } = this.state;
        if (email !== "" && email.includes("@")) {
            await firebaseApp.auth().sendPasswordResetEmail(email);
            this.setState({
                success: true,
                loading: false,
                resetError: ''
            });
        } else {
            this.setState({
                resetError: 'Please enter a valid email address.',
                loading: false,
                success: false
            });
        }
    }

    render() {
        return (
            <>
            <div className="page-title page-title-style-01 bkg-img09">
                <div className="pt-mask-light"></div>
            </div>
            <div className="page-content">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="custom-heading-01">
                                <h2>Reset Password</h2>
                            </div>
                            <form className="wpcf7 wpcf7-contact-us clearfix" onSubmit={this.sendEmail}>
                                <input 
                                    onChange={(e) => this.setState({email: e.target.value})} value={this.state.email} 
                                    type="text" className="wpcf7-textarea" id="email" placeholder="Email"
                                />
                                {this.state.resetError && 
                                <div className="message-boxes errorbox-default">
                                    <div className="notification-container">
                                        <p>{this.state.resetError}</p>
                                    </div>
                                </div>
                                }
                                {this.state.success && 
                                <div className="message-boxes successbox-default">
                                    <div className="notification-container">
                                        <p>An email has been sent to {this.state.email}</p>
                                    </div>
                                </div>
                                }
                                {!this.state.loading && 
                                <button onClick={this.sendEmail} type="button" style={{float: 'left'}} value="Login" className="wpcf7-submit">Reset</button>
                                }
                                {this.state.loading && 
                                <button type="button" className="wpcf7-submit" style={{float: 'left'}}>
                                    <ClipLoader
                                        size={24}
                                        color={"#ffffff"}
                                        loading={this.state.loading}
                                    />
                                </button>
                                }
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            </>
        )
    }
}

const mapStateToProps = state => {
    return state;
}

const mapDispatchToProps = {
    logout
}
  
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(ResetPasswordComponent));