import React from 'react';
import { countries } from './countries';

export const AddressForm = ({prefix, type, data, setAddress}) => {
    return (
        <div className="row">
            <div className="col-md-6">
                <input 
                    onChange={(e) => setAddress(type, "firstname", e.target.value)} 
                    value={data.firstname} 
                    type="text" 
                    className="wpcf7-textarea" 
                    id={`${prefix}_firstname`} 
                    placeholder="First name"
                />
            </div>
            <div className="col-md-6">
                <input 
                    onChange={(e) => setAddress(type, "lastname", e.target.value)} 
                    value={data.lastname} 
                    type="text" 
                    className="wpcf7-textarea" 
                    id={`${prefix}_lastname`} 
                    placeholder="Last name"
                />
            </div>
            <div className="col-md-12">
                <input 
                    onChange={(e) => setAddress(type, "address1", e.target.value)} 
                    value={data.address1} 
                    type="text" 
                    className="wpcf7-textarea" 
                    id={`${prefix}_address1`} 
                    placeholder="Street"
                />
            </div>
            <div className="col-md-12">
                <input 
                    onChange={(e) => setAddress(type, "address2", e.target.value)} 
                    value={data.address2} 
                    type="text" 
                    className="wpcf7-textarea" 
                    id={`${prefix}_address2`} 
                    placeholder=""
                />
            </div>
            <div className="col-md-12">
                <input 
                    onChange={(e) => setAddress(type, "city", e.target.value)} 
                    value={data.city} 
                    type="text" 
                    className="wpcf7-textarea" 
                    id={`${prefix}_city`} 
                    placeholder="City"
                />
            </div>
            <div className="col-md-6">
                <input 
                    onChange={(e) => setAddress(type, "county", e.target.value)} 
                    value={data.county} 
                    type="text" 
                    className="wpcf7-textarea" 
                    id={`${prefix}_county`} 
                    placeholder="County"
                />
            </div>
            <div className="col-md-6">
                <input 
                    onChange={(e) => setAddress(type, "postcode", e.target.value)} 
                    value={data.postcode} 
                    type="text" 
                    className="wpcf7-textarea" 
                    id={`${prefix}_postcode`} 
                    placeholder="Post code"
                />
            </div>
            <div className="col-md-12">
                <select 
                    className="wpcf7-textarea"
                    defaultValue="United Kingdom"
                    onChange={(e) => setAddress(type, "country", e.target.value)}
                >
                    
                    {countries.map((c, i) => {
                        return <option key={i} value={c.name}>{c.name}</option>;
                    })}
                </select>
            </div>
        </div>
    )
}