import React from 'react';
import { connect } from 'react-redux';
import ContactComponent from './../components/contact';

export let Contact = props => {
    return <ContactComponent props={props}/>
}

const mapStateToProps = state => {
    return state;
}
  
export default connect(
    mapStateToProps
)(Contact);