import React from 'react';
import { connect } from 'react-redux';
import ContentComponent from './../../components/content/';

export let Content = (props) => {
    return <ContentComponent stuff={props}/>
}

const mapStateToProps = state => {
    return state;
}
  
export default connect(
    mapStateToProps
)(Content);