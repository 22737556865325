import React from 'react';
import { connect } from 'react-redux';
import AccountOrderEditDetailsComponent from './../../components/account/editOrder';

export let AccountOrderEditDetails = (props) => {
    return <AccountOrderEditDetailsComponent parent={props}/>
}

const mapStateToProps = state => {
    return state;
}
  
export default connect(
    mapStateToProps
)(AccountOrderEditDetails);