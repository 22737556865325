import React from 'react';
import { Link } from 'react-router-dom';

class HomeCarousel extends React.Component {
    componentDidMount() {
        window.VolcannoInclude.owlCarouselInit('featured-pages-carousel');
    }
    
    render() {
        let items = this.props.items.map((item, i) => {
            return <div key={i} className="owl-item">
                <div className="featured-page-box">
                    <div className="media">
                        <img src={item.image.url} alt={item.title}/>
                    </div>
                    <div className="body">
                        <Link to={item.link}>
                            <h2 style={{color: this.props.color}}>{item.title}</h2>
                        </Link>
                    </div>
                </div>
            </div>
        })
        return <div id="featured-pages-carousel" className="owl-carousel">{items}</div>;
    }
}

export default HomeCarousel;