import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { sendInvoice, emptyCart } from '../../actions/';
import { db } from '../../actions/settings';
import { PaymentDetails } from './paymentDetails';
import { AddressText } from './addressText';

class CheckoutCompleteComponent extends React.Component {

    state = {
        items: {},
        orderNumber: this.props.match.params.orderid,
        order: {},
        paymentText: '',
        sending: true
    }

    componentDidMount = async () => {
        try {
            let doc = await db   
                .collection("Orders")
                .doc(this.state.orderNumber)
                .get();
            if (doc.exists && (this.props.user.info.id === doc.data().customerId)) {
                let order = doc.data();
                this.setState({order}, async() => {
                    this.props.emptyCart();
                    if (order.invoice) {
                        await sendInvoice(order.invoice.id);
                    }
                    this.setState({sending:false});
                });
            } else {
                console.log("No order exists");
                this.props.history.push("/account/details");
            }
        } catch(e) {
            console.log(e);
            this.props.history.push("/account/details");
        }
    }

    render() {

        return (
            <>
                <div className="page-title page-title-style-01 bkg-img09">
                    <div className="pt-mask-light"></div>
                </div>
                <div className="page-content">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="message-boxes successbox-default" style={{marginBottom:50+'px'}}>
                                    <div className="icon-container">
                                        <i className="fa fa-check"></i>
                                    </div>
                                    <div className="notification-container" style={{paddingLeft:100+'px'}}>
                                        <p>Checkout Complete. You can find order {this.state.orderNumber} in the list of <Link to="/account/orders">your orders.</Link></p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <h4>Order Details</h4>
                                        <p><b>Delivery Address (if applicable)</b></p>
                                        <AddressText address={this.state.order.delivery}/>
                                        <p><b>Billing Address</b></p>
                                        <AddressText address={this.state.order.billing}/>
                                    </div>
                                    <div className="col-md-6">
                                        <PaymentDetails order={this.state.order} parent={this}/>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="table-responsive">
                                                    <table className="table events-table">
                                                        <thead>
                                                            <tr>
                                                                <th>Product</th>
                                                                <th>Price</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.order.items && 
                                                            <>
                                                            {this.state.order.items.map((product, i) => {
                                                                return <tr key={i}>
                                                                    <td>{product.title} x{product.quantity}</td>
                                                                    <td>£{(product.priceTotal*product.quantity).toFixed(2)}</td>                                            
                                                                </tr>
                                                            })}   
                                                            </>
                                                            }
                                                            <tr>
                                                                <td>Total:</td>
                                                                <td>£{this.state.order.total}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = state => {
    return state;
}

const mapDispatchToProps = {
    emptyCart
}
  
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(CheckoutCompleteComponent));