import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";
import ClipLoader from "react-spinners/ClipLoader";
import { validateForm, updateMetaTags } from './../actions/';

const recaptchaRef = React.createRef();

class ContactComponent extends React.Component {

    state = {
        sending: false,
        success: false,
        error: false,
        name: '',
        email: '',
        phone: '',
        subject: '',
        message: '',
        disabled: true
    }

    componentDidMount() {
        this.props.updateMetaTags({
            title: 'Contact Us - The British Tunnelling Society',
            description: "Contact the British Tunnelling Society by sending us a message.",
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: 'Contact BTS, British Tunnelling Society, BTS, Tunnelling, Tunnels, Mining, TBM'
                }
            }
        });
    }

    sendEmail = async (e) => {
        e.preventDefault();
        this.setState({sending: true, error: false, success: false}, async () => {
            let { name, email, phone, subject, message } = this.state;
            let checkArr = [
                ["name", name],
                ["email", email],
                ["subject", subject],
                ["message", message],
            ];
            let error = validateForm(checkArr);
            if (!error) {
                let data = this.state;
                let send = await fetch("https://us-central1-bts-org-uk.cloudfunctions.net/api/email/contact", {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    method: 'POST',
                    body: JSON.stringify(data)
                });
                let sent = await send.text();
                if (sent) {
                    this.setState({
                        sending: false,
                        success: true,
                        name: '',
                        email: '',
                        phone: '',
                        subject: '',
                        message: ''
                    });
                    recaptchaRef.current.reset();
                } else {
                    this.setState({
                        sending: false,
                        error: true
                    });
                }
            }
        });
    }
    
    captcha = (value) => {
        if (value) {
            this.setState({
                disabled: false
            });
        }
    }

    render() {
        let { email, phone } = this.props.storage.settings;
        return (
            <>
                <div className="page-title page-title-style-01 bkg-img09">
                    <div className="pt-mask-light"></div>
                </div>
                <div className="page-content">
                    <div className="container">
                        <div className="row">
                        <div className="col-md-6">
                            <div className="custom-heading-03">
                                <h3>Contact us</h3>
                            </div>
                            <form className="wpcf7 wpcf7-contact-us clearfix" onSubmit={this.sendEmail}>
                                <input 
                                    onChange={(e) => this.setState({name: e.target.value})} value={this.state.name} 
                                    type="text" className="wpcf7-text" id="name" placeholder="Name (required)"
                                />
                                <input 
                                    onChange={(e) => this.setState({email: e.target.value})} value={this.state.email} 
                                    type="email" className="wpcf7-email" id="email" placeholder="Email (required)"
                                />
                                <input 
                                    onChange={(e) => this.setState({phone: e.target.value})} value={this.state.phone} 
                                    type="number" className="wpcf7-text" id="phone" placeholder="Phone"
                                />
                                <input 
                                    onChange={(e) => this.setState({subject: e.target.value})} value={this.state.subject} 
                                    type="text" className="wpcf7-text" id="subject" placeholder="Subject (required)"
                                />
                                <textarea 
                                    rows="8" onChange={(e) => this.setState({message: e.target.value})}
                                    className="wpcf7-textarea" id="message" 
                                    placeholder="Message (required)"
                                    value={this.state.message}
                                >
                                </textarea>
                                <ReCAPTCHA
                                    sitekey="6Lcc69QUAAAAAKj8CLhwjc5SaUEiDzGPa7ryUZeN"
                                    onChange={this.captcha}
                                    ref={recaptchaRef}
                                />
                                {this.state.error && 
                                <div className="message-boxes errorbox-default">
                                    <div className="notification-container">
                                        <p>Error sending, please try again.</p>
                                    </div>
                                </div>
                                }
                                {this.state.success && 
                                <div className="message-boxes successbox-default">
                                    <div className="notification-container">
                                        <p>Email sent. We'll be in touch as soon as possible.</p>
                                    </div>
                                </div>
                                }
                                {!this.state.disabled && !this.state.sending && 
                                <input type="submit" value="submit" className="wpcf7-submit"/>
                                }  
                                {this.state.sending && 
                                <button type="button" className="wpcf7-submit">
                                    <ClipLoader
                                        size={24}
                                        color={"#ffffff"}
                                        loading={this.state.sending}
                                    />
                                </button>
                                }
                            </form>
                        </div>
                        <div className="col-md-6">
                            <div className="custom-heading-03">
                                <h3>Company info</h3>
                            </div>
                            <ul className="fa-ul default">
                                <li>
                                    <i className="lynny-phone-1"></i>
                                    <p>
                                        {phone}
                                    </p>
                                </li>
                                <li>
                                    <i className="lynny-mail-duplicate"></i>
                                    <p>
                                        <a href={`mailto:${email}`}>{email}</a>
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapDispatchToProps = {
    updateMetaTags
}

const mapStateToProps = state => {
    return state;
}
  
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(ContactComponent));