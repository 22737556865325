import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ClipLoader from "react-spinners/ClipLoader";
import { db, firebaseD } from '../../actions/settings';
import { logout, validateForm, login, setMetaData } from '../../actions/';
import { AccountNav } from './nav';

class AccountDetailsComponent extends React.Component {

    state = {
        email: this.props.user.info.email,
        firstname: this.props.user.info.firstname,
        lastname: this.props.user.info.lastname,
        saving: false,
        updated: false,
        mailingLists: [],
        mailingLinks: [],
        addingMailing: false
    }

    componentDidMount = async () => {
        this.props.setMetaData({
            title: 'My Account - The British Tunnelling Society',
            description: "My account details with the British Tunnelling Society."
        });
        let mailingLists = [], mailingLinks = [];
        let docs = await db
            .collection("MailingLists")
            .where("active", "==", true)
            .orderBy("displayOrder")
            .get();
        if (docs.size > 0) {
            docs.forEach(doc => mailingLists.push(doc.data()));
            let linkDocs = await db
                .collection("MailingListLinks")
                .where("customerId", "==", this.props.user.info.id)
                .get();
            if (linkDocs.size > 0) {
                linkDocs.forEach(lDoc => mailingLinks.push(lDoc.data()))
            }

            this.setState({mailingLists, mailingLinks});
        }
    }

    logOutSession = async () => {
        await firebaseD.auth().signOut();
        this.props.history.push("/");
        this.props.logout();
    }

    updateUser = async(e) => {
        e.preventDefault();
        this.setState({saving: true}, async() => {
            let { firstname, lastname, email } = this.state;
            let checkArr = [
                ["firstname", firstname],
                ["lastname", lastname],
                ["email", email]
            ];
            let error = validateForm(checkArr);
            if (!error) {
                await db.collection("Customers")
                .doc(this.props.user.info.id)
                .update({firstname,lastname,email});

                let savedData = {firstname: firstname, lastname: lastname, email: email};
                let newData = {...this.props.user.info, ...savedData};
                this.props.login(newData);

                this.setState({saving: false, updated: true}, () => {
                    setTimeout(() => {
                        this.setState({
                            updated: false
                        });
                    }, 3000);
                });
            }
        });
    }

    updateMailing = async (id, checked) => {
        this.setState({addingMailing: true}, async () => {
            await db
                .collection("MailingListLinks")
                .doc(id+'=='+this.props.user.info.id)
                .set({
                    id,
                    hasSelected: checked,
                    customerId: this.props.user.info.id,
                    email: this.props.user.info.email,
                    firstname: this.props.user.info.firstname,
                    lastname: this.props.user.info.lastname
                });
            let mailingLinks = this.state.mailingLinks;
            if (mailingLinks.filter(l => l.id === id).length > 0) {
                mailingLinks.map((l, i) => {
                    if (l.id === id) mailingLinks[i].hasSelected = checked;
                });
            } else {
                mailingLinks.push({
                    id,
                    hasSelected: checked,
                    customerId: this.props.user.info.id,
                    email: this.props.user.info.email,
                    firstname: this.props.user.info.firstname,
                    lastname: this.props.user.info.lastname
                });
            }
            this.setState({mailingLinks, addingMailing: false});
        });
    }

    checkMailingSelected = (id) => {
        return this.state.mailingLinks.some(l => l.id === id && l.hasSelected);
    }

    render() {
        return (
            <>
            <div className="page-title page-title-style-01 bkg-img09">
                <div className="pt-mask-light"></div>
            </div>
            <div className="page-content">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <div className="custom-heading-01">
                                <h2>My Account</h2>
                            </div>
                            <form className="wpcf7 wpcf7-contact-us clearfix" onSubmit={this.updateUser}>
                                <input 
                                    onChange={(e) => this.setState({firstname: e.target.value})} value={this.state.firstname} 
                                    type="text" className="wpcf7-text" id="firstname" placeholder="First name (required)"
                                />
                                <input 
                                    onChange={(e) => this.setState({lastname: e.target.value})} value={this.state.lastname} 
                                    type="text" className="wpcf7-text" id="lastname" placeholder="Last name (required)"
                                />
                                <input 
                                    onChange={(e) => this.setState({email: e.target.value})} value={this.state.email} 
                                    type="email" className="wpcf7-textarea" id="email" placeholder="Email Address"
                                />
                                {this.state.error && 
                                <div className="message-boxes errorbox-default">
                                    <div className="notification-container">
                                        <p>Error saving, please try again.</p>
                                    </div>
                                </div>
                                }
                                {this.state.updated && 
                                <div className="message-boxes successbox-default">
                                    <div className="notification-container">
                                        <p>Your details have been updated.</p>
                                    </div>
                                </div>
                                }
                                {!this.state.saving && 
                                <input type="submit" value="Update" className="wpcf7-submit"/>
                                }  
                                {this.state.saving && 
                                <button type="button" className="wpcf7-submit">
                                    <ClipLoader
                                        size={24}
                                        color={"#ffffff"}
                                        loading={this.state.saving}
                                    />
                                </button>
                                }
                            </form>
                        </div>
                        <aside className="col-md-4 aside-right">
                            <ul className="aside-widgets">
                                <li className="widget widget_nav_menu clearfix">
                                    <div className="menu-quick-links-container">
                                        <AccountNav comp={this}/>
                                    </div>
                                </li>
                            </ul>
                        </aside>
                    </div>
                    <div className="row">
                        <div className="col-md-8">
                            <div className="custom-heading-01">
                                <h2>Mailing Lists</h2>
                            </div>
                            <form className="wpcf7 wpcf7-contact-us clearfix">
                                {this.state.mailingLists.map((list) => {
                                    let isSelected = this.checkMailingSelected(list.id);
                                    return <label key={list.id} className="chk-container"><b>{list.title}</b> 
                                        <ClipLoader
                                            size={16}
                                            color={"#000"}
                                            loading={this.state.addingMailing}
                                        />
                                        {!this.state.addingMailing && 
                                        <>
                                        <input  
                                            type="checkbox"
                                            checked={isSelected}
                                            onChange={(e) => this.updateMailing(list.id, e.target.checked)}
                                            value={isSelected}
                                        />
                                        <span className="checkmark"></span>
                                        </>
                                        }
                                        <p>{list.description}</p>
                                    </label>
                                })}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            </>
        )
    }
}

const mapStateToProps = state => {
    return state;
}

const mapDispatchToProps = {
    logout,
    login,
    setMetaData
}
  
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(AccountDetailsComponent));