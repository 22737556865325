import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { 
    getCartValues, removeFromCart, emptyCart, validateForm, 
    payByInvoice, payByCard, adjustStock 
} from '../../actions/';
import { db, firebaseD } from '../../actions/settings';
import ClipLoader from "react-spinners/ClipLoader";
import StripeCheckout from 'react-stripe-checkout';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { AddressForm } from './address';

const stripePromise = loadStripe('pk_live_vLYqh9cYcLQrEopu3eoEPdMj00oEtUoTo9');

class CheckoutComponent extends React.Component {

    state = {
        cart: this.props.cart,
        total: 0.00,
        subTotal: 0.00,
        paying: false,
        payingText: '',
        error: '',
        delivery: {
            firstname: '',
            lastname: '',
            address1: '',
            address2: '',
            city: '',
            postcode: '',
            county: '',
            country: 'United Kingdom',
            phone: ''
        },
        billing: {
            firstname: '',
            lastname: '',
            address1: '',
            address2: '',
            city: '',
            postcode: '',
            county: '',
            country: 'United Kingdom',
            phone: ''
        },
        billingIsDelivery: true,
        stripeSet: false
    }

    componentDidMount() {
        console.log("qs", this.props.cart.questionnaires);
        this.props.cart.questionnaires.map((q, i) => {
            if (!q.answered) {
                this.props.history.push(`/basket/checkout/questions/1`);
            }
        });
        let { subTotal, total } = getCartValues(this.state.cart.products);
        this.setState({
            subTotal, total
        });
    }

    cancelOrder = () => {
        this.setState({
            subTotal: 0.00,
            total: 0.00
        }, () => {
            this.props.emptyCart();
            this.props.history.push("/basket/");
        });
    }

    setAddress = (type, key, val) => {
        var currentVal = {...this.state[type]};
        currentVal[key] = val;
        this.setState({[type]: currentVal});
    }

    saveAddress = () => {
        let { billingIsDelivery, billing, delivery } = this.state;
        if (billingIsDelivery) {
            billing = delivery;
            this.setState({billing: delivery});
        }
        let checkArr = [
            ["d_firstname", delivery.firstname],
            ["d_lastname", delivery.lastname],
            ["d_address1", delivery.address1],
            ["d_city", delivery.city],
            ["d_postcode", delivery.postcode],
            ["d_country", delivery.country],
            ["b_firstname", billing.firstname],
            ["b_lastname", billing.lastname],
            ["b_address1", billing.address1],
            ["b_city", billing.city],
            ["b_postcode", billing.postcode],
            ["b_country", billing.country]
        ];
        return validateForm(checkArr); 
    }

    createInvoice = async () => {        
        let error = this.saveAddress();
        if (!error) {
            let { delivery, billing, billingIsDelivery } = this.state;
            if (billingIsDelivery) billing = delivery;
            this.setState({error: false, paying: true, payingText: 'Creating invoice'}, async () => {
                const data = {
                    id: this.props.user.info.id,
                    items: this.state.cart.products,
                    orderid: this.props.cart.order.number,
                    billing: billing
                };
                try {
                    let { complete, error } = await adjustStock(this.props.cart.products);
                    if (complete) {
                        const invoice = await payByInvoice(data);
                        if (!invoice.id) throw new Error("Unable to create the invoice");
                        await db
                            .collection("Orders")
                            .doc(this.props.cart.order.number)
                            .set({
                                id: this.props.cart.order.number,
                                items: this.props.cart.products,
                                invoice: invoice,
                                total: this.state.total,
                                subTotal: this.state.subTotal,
                                paid: false,
                                createdDate: firebaseD.firestore.FieldValue.serverTimestamp(),
                                delivery: delivery,
                                billing: billing,
                                customerId: this.props.user.info.id,
                                customerName: this.props.user.info.firstname + ' ' + this.props.user.info.lastname,
                                refunded: false,
                                deleted: false
                            });
                        this.props.cart.questionnaires.map(async(q, i) => {
                            await db
                                .collection(`Orders/${this.props.cart.order.number}/Questionnaires`)
                                .doc(`questions_${i}`)
                                .set(q);
                        });
                        
                        this.props.history.push(`/basket/complete/${this.props.cart.order.number}`);
                    } else {
                        this.setState({
                            error: true,
                            errorMsg: error,
                            paying: false
                        });
                    }
                } catch(e) {
                    console.log("Payment error", e.message);
                    this.setState({
                        error: true,
                        errorMsg: "Unable to create the invoice. Please try again.",
                        paying: false
                    });
                }
            });
        }
    }

    cardPayment = () => {
        let error = this.saveAddress();
        if (!error) {
            window.$("#stripeCardBtn").click();
        }
    }

    onStripeToken = async (token) => {
        let { delivery, billing, billingIsDelivery } = this.state;
        if (billingIsDelivery) billing = delivery;
        this.setState({error: false, paying: true, payingText: 'Payment processing'}, async () => {
        
            const data = {
                id: this.props.user.info.id,
                token: token.id,
                amount: (this.state.total*100),
                delivery: this.state.delivery,
                email: this.props.user.info.email
            };
            try {
                let { complete, error } = await adjustStock(this.props.cart.products);
                if (complete) {
                    const charge = await payByCard(data);
                    if (charge.status === 'succeeded') {
                        await db
                            .collection("Orders")
                            .doc(this.props.cart.order.number)
                            .set({
                                id: this.props.cart.order.number,
                                items: this.props.cart.products,
                                payment: charge,
                                total: this.state.total,
                                subTotal: this.state.subTotal,
                                createdDate: firebaseD.firestore.FieldValue.serverTimestamp(),
                                delivery: delivery,
                                billing: billing,
                                customerId: this.props.user.info.id,
                                customerName: this.props.user.info.firstname + ' ' + this.props.user.info.lastname,
                                paid: true,
                                paidDate: firebaseD.firestore.FieldValue.serverTimestamp(),
                                refunded: false,
                                deleted: false
                            });
                        this.props.cart.questionnaires.map(async(q,i) => {
                            await db
                                .collection(`Orders/${this.props.cart.order.number}/Questionnaires`)
                                .doc(`questions_${i}`)
                                .set(q);
                        });
                        
                        this.props.history.push(`/basket/complete/${this.props.cart.order.number}`);
                    } else {
                        this.setState({
                            error: true,
                            errorMsg: "This payment has been declined. Please try again.",
                            paying: false
                        });
                    }
                } else {
                    this.setState({
                        error: true,
                        errorMsg: error,
                        paying: false
                    });
                }
            } catch(e) {
                console.log("Payment error", e.message);
                this.setState({
                    error: true,
                    errorMsg: "Unable to process the payment. Please try again.",
                    paying: false
                });
            }
        });
    }

    render() {
        let { delivery, billing, billingIsDelivery } = this.state;
        return (
            <Elements stripe={stripePromise}>
                <div className="page-title page-title-style-01 bkg-img09">
                    <div className="pt-mask-light"></div>
                </div>
                <div className="page-content">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="custom-heading-01">
                                    <h2>Checkout</h2>
                                    {this.props.cart.questionnaires.length > 0 &&
                                    <div className="message-boxes infobox-default">
                                        <div className="notification-container">
                                            <p><Link to="/basket/checkout/questions/1">Review Product Questionnaires</Link></p>
                                        </div>
                                    </div>
                                    }
                                </div>
                                <form className="wpcf7 wpcf7-contact-us clearfix" onSubmit={this.handleSubmit}>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <h4>Delivery Address</h4>
                                            <p>It may be that items need to be delivered, so please select a delivery address from your address book or add a new one.</p>
                                            <AddressForm 
                                                prefix="d"
                                                type="delivery"
                                                data={delivery}
                                                setAddress={this.setAddress}
                                            />
                                            <div className="custom-heading-01">
                                                <h4>Billing Address</h4>
                                                <p>Please enter your billing address or use the details entered for the delivery address.</p>
                                            </div>
                                            <label className="chk-container">Same as delivery address
                                                <input  
                                                    type="checkbox"
                                                    checked={billingIsDelivery}
                                                    onChange={(e) => this.setState({billingIsDelivery: e.target.checked})}
                                                    value={billingIsDelivery}
                                                />
                                                <span className="checkmark"></span>
                                            </label>
                                            {!billingIsDelivery &&
                                            <AddressForm 
                                                prefix="b"
                                                type="billing"
                                                data={billing}
                                                setAddress={this.setAddress}
                                            />
                                            }
                                        </div>
                                        <div className="col-md-6">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="table-responsive">
                                                        <table className="table events-table">
                                                            <thead>
                                                                <tr>
                                                                    <th>Product</th>
                                                                    <th>Price</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.props.cart.products.map((product, i) => {
                                                                    return <tr key={i}>
                                                                        <td>{product.title} x{product.quantity}</td>
                                                                        <td>£{(product.priceTotal*product.quantity).toFixed(2)}</td>                                            
                                                                    </tr>
                                                                })}   
                                                                {this.props.cart.products.length === 0 && 
                                                                <tr><td colSpan="2">No items added.</td></tr>
                                                                }
                                                                {this.props.cart.products.length > 0 && 
                                                                <tr>
                                                                    <td>Total:</td>
                                                                    <td>£{this.state.total}</td>
                                                                </tr>
                                                                }
                                                            </tbody>
                                                            <tfoot>
                                                                
                                                            </tfoot>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            {this.state.error && 
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="message-boxes errorbox-default">
                                                        <div className="notification-container">
                                                            <p>{this.state.errorMsg}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            }
                                            <div className="row">
                                                {!this.state.paying && this.props.cart.products.length > 0 &&
                                                <div className="col-md-12">
                                                    <div className="pull-left">
                                                        <button 
                                                            className="btn btn-grey"
                                                            type="button"
                                                            onClick={this.cancelOrder}
                                                        >
                                                            Cancel Order
                                                        </button>
                                                    </div>
                                                    <div className="pull-right">
                                                        <button 
                                                            className="btn btn-success marg-right-20"
                                                            type="button"
                                                            onClick={this.createInvoice}
                                                        >
                                                            Pay By Invoice
                                                        </button>
                                                        <button 
                                                            className="btn btn-success"
                                                            type="button"
                                                            onClick={this.cardPayment}
                                                        >
                                                            Pay By Card
                                                        </button>
                                                        <StripeCheckout
                                                            token={this.onStripeToken}
                                                            stripeKey="pk_live_vLYqh9cYcLQrEopu3eoEPdMj00oEtUoTo9"
                                                            currency="GBP"
                                                            name="BTS Payment"
                                                            image="/img/logo.gif"
                                                            email={this.props.user.info.email}
                                                            amount={(this.state.total*100)}
                                                        >
                                                            <button 
                                                                type="button"
                                                                style={{display:'none'}}
                                                                id="stripeCardBtn"
                                                            >
                                                                Pay By Card
                                                            </button>
                                                        </StripeCheckout>
                                                    </div>
                                                </div>
                                                }
                                                {this.state.paying && 
                                                <div className="col-md-12">
                                                    <div className="pull-left">
                                                        <span>{this.state.payingText}... {` `}</span>
                                                        <ClipLoader
                                                            size={16}
                                                            color={"#5cb85c"}
                                                            loading={this.state.paying}
                                                        />
                                                    </div>
                                                </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Elements>
        )
    }
}

const mapStateToProps = state => {
    return state;
}

const mapDispatchToProps = {
    removeFromCart,
    emptyCart
}
  
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(CheckoutComponent));