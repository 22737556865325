import React from 'react';
import { connect } from 'react-redux';
import AccountDetailsComponent from './../../components/account/details';

export let AccountDetails = (props) => {
    return <AccountDetailsComponent parent={props}/>
}

const mapStateToProps = state => {
    return state;
}
  
export default connect(
    mapStateToProps
)(AccountDetails);