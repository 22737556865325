const defaultState = {
    navigation: [],
    news: [],
    events: [],
    subNavs: [],
    sliders: [],
    codes: [],
    settings: null
}

export default function storage (state = defaultState, action) {
    switch(action.type) {
        case "STORE_CODES":
            return {
                ...state,
                codes: action.payload.codes
            }
        case "STORE_NAVIGATION":
            return {
                ...state,
                navigation: action.payload.navigation,
                subNavs: action.payload.subNavs
            }
        case "STORE_SLIDERS":
            return {
                ...state,
                sliders: action.payload.sliders
            }
        case "STORE_SETTINGS":
            return {
                ...state,
                settings: action.payload.settings
            }
        case "STORE_NEWS":
            return {
                ...state,
                news: action.payload.news
            }
        case "STORE_EVENTS":
            return {
                ...state,
                events: action.payload.events
            }
        default:
            return state;
    }
}