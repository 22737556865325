import React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
// containers
import Home from './containers/home';
import MainContainer from './containers/main';

import { db, firebaseD } from './actions/settings';
import { storeCodes, storeNavigation, storeSettings, storeNews, storeEvents, logout, setMetaData } from './actions/index';
import Content from './containers/content/';
import Product from './containers/products/';
import Contact from './containers/contact';

import Account from './containers/account/';
import AccountDetails from './containers/account/details';
import AccountOrders from './containers/account/orders';
import AccountOrderDetails from './containers/account/orderDetails';
import AccountOrderEditDetails from './containers/account/editOrder';
import ResetPassword from './containers/account/reset';
import ResetConfirmPassword from './containers/account/confirm';

import Basket from './containers/checkout/basket';
import Checkout from './containers/checkout/summary';
import CheckoutComplete from './containers/checkout/complete';
import Questionnaires from './containers/checkout/questions';
import ShowAllContainer from './containers/content/all';

import SearchPages from './components/content/search';

import { PageNotFound } from './components/404.js';
import { Helmet } from "react-helmet";

class App extends React.Component {

  state = {
    tabs: []
  }
  
  requireAuth = () => {
    return this.props.user.loggedIn;
  }

  makeLink = (title) => {
    return title.split(' ').join('-').toLowerCase();
  }

  componentDidMount = async () => {
    firebaseD.auth().onAuthStateChanged((user) => {
      if (!user) {
        this.props.logout();
      }
    });
    let tabs = [], navItems = [], subNavs = {}, newsItems = [], eventsItems = [];
    let cDoc = await db
      .collection("Settings")
      .doc("Codes")
      .get();

    // store codes in redux
    let codes = cDoc.data();

    ///// NAVIGATION ////
    let newNavCode = codes.navigation;
    let propCodes = (this.props.storage.codes) ? this.props.storage.codes : {};
    let oldNavCode = propCodes.navigation;
    if ("a" === "b" && newNavCode === oldNavCode && this.props.storage.navigation && this.props.storage.navigation.length > 0) {
      navItems = this.props.storage.navigation;
    } else {
      let tDocs = await db.collection("Tabs")
        .where('active', '==', true)
        .where('deleted', '==', false)
        .orderBy('displayOrder')
        .get();
      tDocs.forEach((doc) => navItems.push(doc.data()));
    }
    navItems.sort(function(a, b) {
      return Number(a.displayOrder|| 0) - Number(b.displayOrder|| 0);
    });
    navItems.map((navOne) => {
      if (navOne.parentTab.id === "") {
        let navTwo = navItems.filter(navTwo => navTwo.parentTab.id === navOne.id);
        subNavs[navOne.id] = {"subNavs": navTwo};
      }
    });
    navItems.sort(function(a, b) {
      return Number(a.displayOrder) - Number(b.displayOrder);
    });
    this.props.storeNavigation(navItems, subNavs);
    navItems.map((tab) => {
      tabs.push(
        <Route key={tab.id} exact path={`/${this.makeLink(tab.title)}/:page`} render={props => <Content {...props} />}/>,
        <Route key={tab.id + "_sub"} exact path={`/${this.makeLink(tab.title)}/:page/:subpage`} render={props => <Content {...props} />}/>
      );
    });
    this.setState({tabs});

    ///// LATEST NEWS ////
    let newNewsCode = codes.news;
    let oldNewsCode = propCodes.news;
    if (newNewsCode === oldNewsCode && this.props.storage.news && this.props.storage.news.length > 0) {
      newsItems = this.props.storage.news;
    } else {
      let nDocs = await db.collection("Pages")
        .where('active', '==', true)
        .where('deleted', '==', false)
        .where('news', '==', true)
        .orderBy('publishDate', 'desc')
        .limit(3)
        .get();
      if (nDocs.size > 0) {
        nDocs.forEach((doc) => {
          let dta = doc.data();
          dta.createdDate = doc.data().createdDate.toDate();
          dta.publishDate = doc.data().publishDate.toDate();
          newsItems.push(dta);
        });
      }
    }
    this.props.storeNews(newsItems);

    ///// EVENTS ////
    let newEventsCode = codes.events;
    let oldEventsCode = propCodes.events;
    if (newEventsCode === oldEventsCode && this.props.storage.events && this.props.storage.events.length > 0) {
      eventsItems = this.props.storage.events;
    } else {
      let eDocs = await db.collection("Pages")
        .where('active', '==', true)
        .where('deleted', '==', false)
        .where('event', '==', true)
        .orderBy('eventDate', 'desc')
        .limit(3)
        .get();
        if (eDocs.size > 0) eDocs.forEach((doc) => {
          let dta = doc.data();
          if (doc.data().eventDate) {
            dta.eventDate = doc.data().eventDate.toDate();
          } else {
            dta.eventDate = null;
          }
          eventsItems.push(dta);
        });
    }
    this.props.storeEvents(eventsItems);

    ///// SETTINGS ////

    let newSettingsCode = codes.settings;
    let oldSettingsCode = propCodes.settings;
    let sDoc;
    if (newSettingsCode === oldSettingsCode && this.props.storage.settings) {
      sDoc = this.props.storage.settings;
    } else {
      let doc = await db
        .collection("Settings")
        .doc("Main")
        .get();
      sDoc = doc.data();
    }
    this.props.storeSettings(sDoc);
    this.props.storeCodes(codes);
  }

  render() {
    // firing event to be picked up in /js/index.js to give header margin-top;
    var event = new Event('changing');
    window.dispatchEvent(event);
    return (
      <Router>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{this.props.meta.title}</title>
          <meta name="description" content={this.props.meta.description} />
        </Helmet>
        <MainContainer storage={this.props.storage}>
          <Switch>
          {this.state.tabs}
            <Route exact path="/" component={Home}/>
            <Route exact path="/search" component={SearchPages}/>
            <Route exact path="/contact" component={Contact}/>
            <Route exact path="/reset" component={ResetPassword}/>
            <Route exact path="/reset/confirm" component={ResetConfirmPassword}/>
            <Route exact path="/account" component={Account}/>
            <Route exact path="/pages/:page" component={Content}/>
            <Route exact path="/products/:link" component={Product}/>
            <Route exact path="/basket/" component={Basket}/>
            <Route exact path="/basket/complete/:orderid" component={CheckoutComplete} />
            <Route exact path="/all/news" component={ShowAllContainer}/>
            <Route exact path="/all/events" component={ShowAllContainer}/>
            <Route 
              path="/basket/checkout" 
              render={() =>
                !this.requireAuth() ? (
                  <Redirect to={{pathname: "/account", redirectTo: "/basket/checkout"}} />
                ) : (
                  <>
                  <Route exact path="/basket/checkout" component={Checkout} />
                  <Route exact path="/basket/checkout/questions/:index" component={Questionnaires} />
                  </>
                )
              }  
            />
            <Route  
              path="/account/" 
              render={() =>
                !this.requireAuth() ? (
                  <Redirect to="/account"/>
                ) : (
                  <>
                  <Route exact path="/account/details" component={AccountDetails} />
                  <Route exact path="/account/orders" component={AccountOrders} />
                  <Route exact path="/account/orders/:orderid" component={AccountOrderDetails} />
                  <Route exact path="/account/orders/:orderid/edit" component={AccountOrderEditDetails} />
                  </>
                )
              }  
            />
            <Route exact path="/404" component={PageNotFound}/>
          </Switch>
        </MainContainer>
      </Router>
    )
  }
}

const mapStateToProps = state => {
    return state;
}

const mapDispatchToProps = {
  storeCodes,
  storeNavigation,
  storeSettings,
  storeNews,
  storeEvents,
  logout,
  setMetaData
}
  
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(App);