import React from 'react';
import { Link } from 'react-router-dom';

export const PageNotFound = () => {
    return <>
        <div className="page-title page-title-style-01 bkg-img09">
            <div className="pt-mask-light"></div>
        </div>
        <div className="page-content">
            <div className="container">
                <div className="row">
                    <div className="col-md-8">
                        <div className="custom-heading-01">
                            <h2>Page Not Found</h2>
                        </div>
                        <p>It looks like something went wrong and our team have been made aware.</p>
                        <Link to="/" className="btn btn-success btn-small">Back Home</Link>
                    </div>
                </div>
            </div>
        </div>
    </>
}