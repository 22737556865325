import React from 'react';
import { connect } from 'react-redux';
import ShowAll from './../../components/content/all';

export let ShowAllContainer = (props) => {
    return <ShowAll stuff={props}/>
}

const mapStateToProps = state => {
    return state;
}
  
export default connect(
    mapStateToProps
)(ShowAllContainer);