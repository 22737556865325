import React from 'react';
import { connect } from 'react-redux';
import AccountComponent from './../../components/account/';

export let Account = (props) => {
    return <AccountComponent parent={props}/>
}

const mapStateToProps = state => {
    return state;
}
  
export default connect(
    mapStateToProps
)(Account);