import React from 'react';
import { connect } from 'react-redux';
import ProductDetails from './../../components/products/';

export let Product = (props) => {
    return <ProductDetails stuff={props}/>
}

const mapStateToProps = state => {
    return state;
}
  
export default connect(
    mapStateToProps
)(Product);