import React, { useEffect } from 'react';
import { cleanHtml, convertDate } from './../../actions/';
import Navlist from '../../components/navigation/Navlist';

export function StandardArticle({ data, showingAll }) {

  useEffect(() => {

  }, [data]);

  let title = data.title;
  let content = cleanHtml(data.content);
  let cols = (showingAll) ? "12" : "8";
  let html = (
    <div className="container">
      <div className="row">
        <div className={`col-sm-${cols}`}>
          <div className="row mb-80">
            <div className="col-md-12">
              <div className="custom-heading-01">
                <h2>{title}</h2>
                {data.event && data.eventDate &&
                <p>{<b>Event Date:</b>}{` `}{convertDate(data.eventDate.toDate())}</p>
                }
                {data.speakers && 
                <p>{<b>Speakers:</b>}{` `}{data.speakers}</p>
                }
                <div style={{marginBottom: 30+'px'}}>
                  {data.youtube && 
                  <a 
                    className="btn btn-success icon-animated" 
                    href={data.youtube}
                    target="_blank"
                    style={{backgroundColor: 'rgb(92, 184, 92)', marginRight: 20+'px'}}>
                    <span style={{color: 'white', marginBottom: 0}}>
                        <i className="lynny-tv"></i>Video
                    </span>
                  </a>
                  }
                  {data.flyer && 
                  <a 
                    className="btn btn-success icon-animated" 
                    href={data.flyer}
                    target="_blank"
                    style={{backgroundColor: 'rgb(92, 184, 92)', marginRight: 20+'px'}}>
                    <span style={{color: 'white', marginBottom: 0}}>
                        <i className="lynny-download"></i>Download Flyer
                    </span>
                  </a>
                  }
                </div>
              </div>
              <p dangerouslySetInnerHTML={{ __html: content }}></p>
            </div>
          </div>
        </div>
        {!showingAll &&
        <aside className="col-md-4 aside-right">
          <ul className="aside-widgets">
            <li className="widget widget_nav_menu clearfix">
              <Navlist pageId={data.id} />
            </li>
          </ul>
        </aside>
        }
      </div>
    </div>
  );
  return html;
};
