const defaultState = {
    title: 'The British Tunnelling Society',
    description: "The British Tunnelling Society (BTS) is an associated society of the ICE and was formed in 1971 to provide a forum for meetings and discussion for the mutual benefit of anyone with an interest in a wide range of tunnel related matters."
}

export default function meta (state = defaultState, action) {
    switch(action.type) {
        case "SET_METADATA":
            return {
                ...state,
                title: action.payload.title,
                desription: action.payload.description
            }
        case "RESET_TAGS":
            return {
                ...state,
                tags: defaultState.tags
            }
        default:
            return state;
    }
}