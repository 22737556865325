import React from 'react';
import { connect } from 'react-redux';
import CheckoutComponent from './../../components/checkout/summary';

export let Checkout = (props) => {
    return <CheckoutComponent parent={props}/>
}

const mapStateToProps = state => {
    return state;
}
  
export default connect(
    mapStateToProps
)(Checkout);