const defaultState = {
    products: [],
    questionnaires: [],
    order: {
        number: ''
    }
}

export default function cart (state = defaultState, action) {
    let currentProducts = (state.products) ? state.products : [];
    switch(action.type) {
        case "ADD_QUESTIONNAIRES":
        let addQs = [];
        let { variant } = action.payload;
        console.log("payload", variant);
        let quantityMultiplier = (variant.quantityMultiplier === undefined) ? 1 : variant.quantityMultiplier;
        let amountNeeded = (quantityMultiplier*variant.quantity);
        for (let x = 1; x <= amountNeeded; x++) {
            variant.questionnaires.map((q) => {
                q['productTitle'] = variant.title;
                q['orderItemId'] = variant.orderItemId;
                addQs.push(q);
            });
        }
        console.log("addQs", addQs);
        return {
            ...state,
            questionnaires: state.questionnaires.concat(addQs)
        }
        case "UPDATE_QUESTIONNAIRES":
            return {
                ...state,
                questionnaires: action.payload.questionnaires
            }
        case "CREATE_ORDER":
            let order = state.order;
            order.number = action.payload.number;
            return {
                ...state,
                products: currentProducts,
                order: order
            }
        case "ADD_TO_CART":
            // not used atm
        case "REMOVE_FROM_CART":
            // remove x amount of questionnaires for that product
            let orderItemId = state.products[action.payload.index].orderItemId;
            let questionnairesLeft = state.questionnaires.filter((q) => q.orderItemId !== orderItemId);
            let productsLeft = state.products.filter((p,i) => i !== action.payload.index);
            return {
                ...state,
                products: productsLeft,
                questionnaires: questionnairesLeft
            }
        case "EMPTY_CART":
            return {
                ...state,
                products: [],
                order: {
                    number: ''
                },
                questionnaires: []
            }
        case "UPDATE_CART":
            return {
                ...state,
                products: action.payload.products
            }
        default:
            return state;
    }
}