import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { updateMetaTags, pageLink, setMetaData } from './../../actions/';
import ReactQueryParams from 'react-query-params';
import ClipLoader from "react-spinners/ClipLoader";

const algoliasearch = require("algoliasearch");
const client = algoliasearch('91JP4FXRQD', '7df3b7ba55d0df1a3e6742f5acb5a11f');

class SearchPages extends ReactQueryParams {

    state = {
        loading: true,
        query: this.queryParams.q || '',
        searchResults: []
    }

    componentWillReceiveProps = (newProps) => {
        if (this.props.location.search !== newProps.location.search) {
            this.setState({query: (newProps.location.search).replace("?q=", "")}, () => {
                this.searchPages();
            });
        }
    }

    componentDidMount = async () => {
        this.searchPages();
        this.props.setMetaData({
            "title": "Search the BTS site - The British Tunnelling Society",
            "description": "Browse articles, events, products and more." 
        });
    }

    searchPages = () => {
        let search = this.state.query;
        const index = client.initIndex('BTSContent');
        index.search(search, {
            filters: `active:"true"`
        })
        .then(({hits}) => {
            this.setState({
                searchResults: hits,
                loading: false
            });
        })
        .catch((err) => {
            console.log(err);
        });
      }

    render() {
        
        return (
            <>
                <div className="page-title page-title-style-01 bkg-img09">
                    <div className="pt-mask-light"></div>
                </div>
                <div className="page-content">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="custom-heading-03">
                                    <h3>Content Search</h3>
                                    <p>Searching for: '{this.state.query}'</p>
                                    {!this.state.loading && 
                                    <div className="table-responsive">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>View Content</th>
                                                    <th>Content Title</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.searchResults.length === 0 &&
                                                <tr><td colSpan="2">No content found</td></tr>
                                                }
                                                {this.state.searchResults.map((item) => {
                                                    return <tr key={item.objectID}>
                                                        <td>
                                                            <Link 
                                                                className="btn btn-small btn-success"
                                                                to={`/pages/${pageLink(item.title)}`}
                                                            >
                                                                View Page
                                                            </Link>
                                                        </td>
                                                        <td>{item.title}</td>
                                                    </tr>
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                    }
                                    {this.state.loading &&
                                    <div style={{width: '100%', textAlign: 'center'}}>
                                        <ClipLoader
                                            size={32}
                                            color={"black"}
                                            loading={this.state.loading}
                                        />
                                    </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapDispatchToProps = {
    updateMetaTags,
    setMetaData
}

const mapStateToProps = state => {
    return state;
}
  
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(SearchPages));