import { db } from './settings';

export const createOrder = (number) => {
    return {
        type: "CREATE_ORDER",
        payload: {
            number
        }
    }
}

export const addToCart = (product) => {
    return new Promise((resolve) => {
        resolve({
            type: "ADD_TO_CART",
            payload: {
                product: product
            }
        })
    });
}

export const addQuestionnaires = (variant) => {
    return new Promise((resolve) => {
        resolve({
            type: "ADD_QUESTIONNAIRES",
            payload: {
                variant: variant
            }
        })
    });
}

export const updateQuestionnaires = (questionnaires) => {
    return new Promise((resolve) => {
        console.log("Updating with", questionnaires)
        resolve({
            type: "UPDATE_QUESTIONNAIRES",
            payload: {
                questionnaires
            }
        })
    });
}

export const updateCart = (products) => {
    return new Promise((resolve) => {
        resolve({
            type: "UPDATE_CART",
            payload: {
                products: products
            }
        })
    });
}

export const removeFromCart = (index) => {
    return new Promise((resolve) => {
        resolve({
            type: "REMOVE_FROM_CART",
            payload: {
                index: index
            }
        })
    });
}

export const updateMetaTags = (tags) => {
    return {
        type: "SET_TAGS",
        payload: {
            tags
        }
    }
}

export const resetMetaTags = () => {
    return {
        type: "RESET_TAGS"
    }
}

export const emptyCart = () => {
    return {
        type: "EMPTY_CART"
    }
}

export const storeNavigation = (navItems, subNavs) => {
    return {
        type: "STORE_NAVIGATION",
        payload: {
            navigation: navItems,
            subNavs: subNavs
        }
    }
}

export const storeSettings = (settings) => {
    return {
        type: "STORE_SETTINGS",
        payload: {
            settings
        }
    }
}

export const storeNews = (news) => {
    return {
        type: "STORE_NEWS",
        payload: {
            news
        }
    }
}

export const storeEvents = (events) => {
    return {
        type: "STORE_EVENTS",
        payload: {
            events
        }
    }
}

export const setCurrentTab = (tab, subTab, botTab) => {
    return {
        type: "SET_CURRENT_TAB",
        payload: {
            currentTab: tab,
            currentSubTab: subTab,
            currentBotTab: botTab
        }
    }
}

export const storeSliders = (sliderItems) => {
    return {
        type: "STORE_SLIDERS",
        payload: {
            sliders: sliderItems
        }
    }
}

export const storeCodes = (codes) => {
    return {
        type: "STORE_CODES",
        payload: {
            codes
        }
    }
}

export const login = (user) => {
    return {
        type: "LOGIN",
        payload: {
            info: user
        }
    }
}

export function logout() {
    return {
        type: "LOGOUT",
        payload: null
    }
}

export const loginError = (err) => {
    return {
        type: "LOGINERROR",
        payload: {
            error: err
        }
    }
}

export const setTabs = (tabs) => {
    return {
        type: "ADD_TABS",
        payload: {
            tabs: tabs
        }
    }
}

export const setMetaData = (data) => {
    return {
        type: "SET_METADATA",
        payload: {
            title: data.title,
            description: data.description
        }
    }
}

export const pageLink = pageTitle => {
    let page = pageTitle.split(' ').join('-');
    return page.toLowerCase();
};

export const stripHtml = (html) => {
    return html.replace(/<\/?[^>]+(>|$)/g, "");
}

export const cleanHtml = (html) => {
    html = html.split("</script>").join("");
    html = html.split("<script>").join("");
    html = html.split("<script").join("");
    return html;
}

export const convertDate = (date) => {
    var d = date.toString();
    return d.substr(0, 21);
}

export const validateForm = arr => {
    let error = false;
    arr.map(i => {
      if (i[1] === '') {
        error = true;
        window.$('#' + i[0]).addClass('is-invalid');
      } else {
        window.$('#' + i[0]).removeClass('is-invalid');
      }
    });
    return error;
};

export const getCartValues = (products) => {
    let subTotal = 0, total = 0;
    if (products) {
        products.map((p) => {
            subTotal = subTotal + (p.priceExVAT*p.quantity);
            total = total + (p.priceTotal*p.quantity);
        });
        return {
            total: total.toFixed(2), 
            subTotal: subTotal.toFixed(2),
            count: products.length
        }
    } else {
        return {
            total: 0.00.toFixed(2), 
            subTotal: 0.00.toFixed(2),
            count: 0
        }
    }
}

export function adjustStock(products) {
    return new Promise((resolve, reject) => {
        for (let x = 0, p = Promise.resolve(); x < products.length; x++) {
            p = p.then(_ => new Promise(async(pResolve) => {
                let ref = db
                    .collection("Products")
                    .doc(products[x].parentId);
                await db.runTransaction(async function(transaction) {
                    let nDoc = await transaction.get(ref);
                    let variants = nDoc.data().variants;
                    variants.map((v) => {
                        if (v.variantId === products[x].variantId) {
                            let remaining = (Number(v.overallStock) - Number(products[x].quantity));
                            if (remaining < 0) {
                                resolve({
                                    complete: false,
                                    error: `Only ${v.overallStock} left in stock for ${v.title}. You were not charged.`
                                });
                                return false;
                            } else {
                                v.overallStock = remaining;
                                // don't save cart quantity - put it back to 1
                                v.quantity = 1;
                            }
                        }
                    });
                    transaction.update(ref, { variants: variants });
                    pResolve();
                });
                if (x === products.length-1) resolve({
                    complete: true,
                    error: ''
                });
            }));
        }
    });
}

const API_URL = "https://us-central1-bts-org-uk.cloudfunctions.net/api";

export function createStripeCustomer(id) {
    return new Promise((resolve, reject) => {
        const endpoint = `${API_URL}/stripe/customer?id=${id}`;
    
        fetch(endpoint)
        .then((data) => data.text())
        .then((res) => resolve(res))
        .catch((err) => { 
            console.log(err);
            reject(err); 
        });

    });
}

export function payByInvoice(data) {
    return new Promise((resolve, reject) => {
        const endpoint = `${API_URL}/stripe/invoice`;
    
        fetch(endpoint, {
            method: 'POST',
            body: JSON.stringify(data)
        })
        .then((data) => data.json())
        .then((res) => resolve(res))
        .catch((err) => { 
            console.log(err);
            reject({
                error: true
            }); 
        });

    });
}

export function payByCard(data) {
    return new Promise((resolve, reject) => {
        const endpoint = `${API_URL}/stripe/charge`;
    
        fetch(endpoint, {
            method: 'POST',
            body: JSON.stringify(data)
        })
        .then((data) => data.json())
        .then((res) => resolve(res))
        .catch((err) => { 
            console.log(err);
            reject(err); 
        });

    });
}

export function sendInvoice(id) {
    return new Promise((resolve, reject) => {
        const endpoint = `${API_URL}/stripe/send?id=${id}`;
    
        fetch(endpoint)
        .then((data) => data.text())
        .then((res) => resolve(res))
        .catch((err) => { 
            console.log(err);
            reject(err); 
        });

    });
}

export function deleteInvoice(id) {
    return new Promise((resolve, reject) => {
        const endpoint = `${API_URL}/stripe/deleteInvoice?id=${id}`;
        fetch(endpoint)
        .then((data) => data.json())
        .then((res) => resolve(res))
        .catch((err) => { 
            console.log(err);
            reject(err); 
        });

    });
}

export function emailReceipt(path, email) {
    return new Promise((resolve, reject) => {
        const endpoint = `${API_URL}/stripe/emailReceipt?pdfpath=${path}&email=${email}`;
        fetch(endpoint)
        .then((data) => data.text())
        .then((res) => resolve(res))
        .catch((err) => { 
            console.log(err);
            reject(err); 
        });

    });
}