import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getCartValues, removeFromCart, emptyCart } from '../../actions/';

class BasketComponent extends React.Component {

    state = {
        cart: this.props.cart,
        total: 0.00,
        subTotal: 0.00
    }

    componentDidMount() {
        this.getTotals();
    }

    getTotals = () => {
        let { subTotal, total } = getCartValues(this.props.cart.products);
        this.setState({
            subTotal, total
        });
    }

    cancelOrder = () => {
        this.setState({
            subTotal: 0.00,
            total: 0.00
        }, () => this.props.emptyCart());
    }

    removeItem = async (i) => {
        await this.props.removeFromCart(i);
        this.getTotals();
    }

    render() {
        return (
            <>
            <div className="page-title page-title-style-01 bkg-img09">
                <div className="pt-mask-light"></div>
            </div>
            <div className="page-content">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="custom-heading-01">
                                <h2>My Basket</h2>
                            </div>
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th style={{width:50+'px'}}>Remove</th>
                                            <th style={{width:70+'px'}}>Qty.</th>
                                            <th>Product</th>
                                            <th>Sub Total</th>
                                            <th>Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.props.cart.products.length === 0 &&
                                        <tr><td colSpan="5">No items added.</td></tr>
                                        }
                                        {this.props.cart.products.map((p, i) => {
                                            return <tr key={i}>
                                                <td>
                                                    <button 
                                                        type="button"
                                                        onClick={() => this.removeItem(i)}
                                                    >
                                                        X
                                                    </button>
                                                </td>
                                                <td>{p.quantity}</td>
                                                <td>{p.title}</td>
                                                <td>£{(p.priceExVAT*p.quantity).toFixed(2)}</td>
                                                <td>£{(p.priceTotal*p.quantity).toFixed(2)}</td>                                 
                                            </tr>
                                        })}
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td style={{paddingTop:50+'px'}} colSpan="5" className="no-top">
                                                <h5 className="checkout-title">Sub Total: </h5>
                                                <h5 className="checkout-title space-gap">£{this.state.subTotal}</h5>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan="5" className="no-top">
                                                <h4 className="checkout-title">Total: </h4>
                                                <h4 className="checkout-title space-gap">£{this.state.total}</h4>
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                    {this.props.cart.products.length > 0 &&
                    <div className="row">
                        <div className="col-md-12">
                            <button className="btn btn-grey" onClick={() => this.cancelOrder()}>Cancel Order</button>
                            <button 
                                className="btn btn-success" 
                                onClick={() => this.props.history.push("/basket/checkout")}
                            >Checkout</button>
                        </div>
                    </div>
                    }
                </div>
            </div>
            </>
        )
    }
}

const mapStateToProps = state => {
    return state;
}

const mapDispatchToProps = {
    removeFromCart,
    emptyCart
}
  
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(BasketComponent));