import { combineReducers } from 'redux';
import user from './user_reducer';
import nav from './nav_reducer';
import storage from './storage_reducer';
import cart from './cart_reducer';
import meta from './meta_reducer';

const rootReducer = combineReducers({
    user,
    nav,
    storage,
    cart,
    meta
});

export default rootReducer;