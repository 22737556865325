import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { firebaseApp } from '../../actions/settings';
import { logout } from '../../actions/';

class ResetPasswordConfirmComponent extends React.Component {

    state = {
        codeConfirmed: false,
        loading: true,
        password: '',
        c_password: '',
        resetError: '',
        code: '',
        success: false
    }

    componentDidMount = async () => {
        const params = new URLSearchParams(this.props.location.search); 
        const code = params.get('oobCode');
        if (code) {
            try {
                let email = await firebaseApp.auth().verifyPasswordResetCode(code);
                this.setState({
                    codeConfirmed: (email) ? true : false, 
                    loading: false, 
                    code: code
                });
            } catch(e) {
                this.setState({loading: false});
            }
        } else {
            this.setState({loading: false});
        }
    }

    setNewPassword = () => {
        let { password, c_password } = this.state;
        if (!password || !c_password || password !== c_password || password.length < 8) {
            this.setState({
                resetError: "Please enter a password longer than 7 characters and ensure both passwords match."});
        } else {
            firebaseApp.auth().confirmPasswordReset(this.state.code, password)
            .then((resp) => {
                this.setState({
                    success: true,
                    loading: false,
                    codeConfirmed: false,
                    resetError: ''
                });
            })
            .catch((err) => {
                this.setState({
                    loading: false,
                    success: false,
                    codeConfirmed: false
                });
            })
        }
    }

    render() {
        return (
            <>
            <div className="page-title page-title-style-01 bkg-img09">
                <div className="pt-mask-light"></div>
            </div>
            <div className="page-content">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="custom-heading-01">
                                <h2>Set New Password</h2>
                            </div>
                            {!this.state.loading && this.state.codeConfirmed && !this.state.success &&
                            <form className="wpcf7 wpcf7-contact-us clearfix" onSubmit={this.setNewPassword}>
                                <input 
                                    onChange={(e) => this.setState({password: e.target.value})} value={this.state.password} 
                                    type="password" className="wpcf7-textarea" id="password" placeholder="Password"
                                />
                                <input 
                                    onChange={(e) => this.setState({c_password: e.target.value})} value={this.state.c_password} 
                                    type="password" className="wpcf7-textarea" id="c_password" placeholder="Confirm password"
                                />   
                                <button onClick={this.setNewPassword} style={{float:'left'}} type="button" value="Login" className="wpcf7-submit">Set Password</button>
                            </form>
                            }
                            {this.state.resetError &&
                                <div className="message-boxes errorbox-default">
                                    <div className="notification-container">
                                        <p>{this.state.resetError}</p>
                                    </div>
                                </div>
                            }
                            {!this.state.loading && !this.state.codeConfirmed && !this.state.success &&
                                <div className="message-boxes errorbox-default">
                                    <div className="notification-container">
                                        <p>This link may have expired, please return to the <Link to="/account/">login page</Link> try to reset your password again.</p>
                                    </div>
                                </div>
                            }
                            {this.state.success &&
                                <div className="message-boxes successbox-default">
                                    <div className="notification-container">
                                        <p>Your password has been reset. You can now <Link to="/account/">login</Link>.</p>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            </>
        )
    }
}

const mapStateToProps = state => {
    return state;
}

const mapDispatchToProps = {
    logout
}
  
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(ResetPasswordConfirmComponent));