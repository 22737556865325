import React from 'react';
import { connect } from 'react-redux';
import ResetPasswordConfirmComponent from './../../components/account/confirm';

export let ResetConfirmPassword = (props) => {
    return <ResetPasswordConfirmComponent parent={props}/>
}

const mapStateToProps = state => {
    return state;
}
  
export default connect(
    mapStateToProps
)(ResetConfirmPassword);