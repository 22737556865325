import React from 'react';

export const AddressText = ({address}) => {
    if (address) {
        return <p>
            {address.firstname + ' ' + address.lastname}<br/>
            {address.address1}<br/>
            {address.address2 !== "" && 
                address.address2
            }
            {address.address2 !== "" && <br/>}
            {address.city}<br/>
            {address.county}<br/>
            {address.postcode}<br/>
            {address.country}
        </p>
    } else {
        return null;
    }
}