import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { db } from './../actions/settings';
import { resetMetaTags, setMetaData  } from './../actions/';
import { OwlSkeleton } from './../skeletons/home';
import HomeCarousel from './../components/carousel';
import { LatestNews } from './content/news';
import { Events } from './content/events';

class HomeComponent extends React.Component {

    state = {
        loading: true,
        carouselItems: [],
        bannerText: '',
        mainImage: 'url(../img/tunnel.jpg)',
        mainVideo: '',
        imageOrVideo: 'image'
    }

    componentDidMount() {
        this.props.resetMetaTags();
        window.VolcannoInclude.owlCarouselInit('featured-pages-carousel');
        window.VolcannoInclude.fpNegativeTop();
        db.collection("Home")
        .doc("Home")
        .get()
        .then((doc) => {
            if (doc.exists) {
                let data = doc.data();
                this.setState({
                    loading: false,
                    bannerText: data.bannerText,
                    carouselItems: data.carouselItems,
                    mainImage: (data.mainImage) ? `url(${data.mainImage})` : 'url(../img/tunnel.jpg)',
                    imageOrVideo: data.imageOrVideo,
                    mainVideo: data.mainVideo
                });
                this.props.setMetaData({
                    "title": "Home - The British Tunnelling Society",
                    "description": "The British Tunnelling Society (BTS) is an associated society of the ICE and was formed in 1971 to provide a forum for meetings and discussion for the mutual benefit of anyone with an interest in a wide range of tunnel related matters." 
                });
            }
        })
        .catch((err) => {
            console.log(err);
        });
    }

    toNews = () => {
        let newsOnNav = window.$(".navbar-default")[0];
        let newsTag = window.$(newsOnNav).find("a").each(function() {
            if (window.$(this).text() === "Latest news") {
                window.$(this).parent().click();
                return true;
            }
        });
        if (!newsTag) {
            window.top.window.location = "/all/news";
        }
    }
    
    render() {
        let bannerText = (this.state.loading) ? null : this.state.bannerText;
        let color = (this.state.imageOrVideo === "video") ? '#ffffff' : '#071740';
        let carousel = (this.state.loading) 
            ? <div id="featured-pages-carousel" className="owl-carousel"><OwlSkeleton/></div> 
            : <HomeCarousel items={this.state.carouselItems} color={color}/>;
        
        return (
            <>
                {this.state.imageOrVideo === "image" &&
                <div className="page-title page-title-style-02 bkg-img09"
                    style={{backgroundImage: this.state.mainImage}}
                >
                    <div className="pt-mask-light"></div>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="pt-heading">
                                    <h1 style={{color: color}}>{bannerText}</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                }
                {this.state.imageOrVideo === "video" &&
                <div className="page-title page-title-style-02"
                >
                    <div className="video-main video-mask-light" style={{
                        
                    }}>
                        <video src={this.state.mainVideo} autoPlay={true} muted></video>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="pt-heading">
                                    <h1 style={{color: color}}>{bannerText}</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                }
                <div className="page-content">
                    <div className="container">
                        <div className="row featured-pages-negative-top">
                            <div className="col-md-12">
                                <div className="carousel-container">
                                    {carousel}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-content" style={{backgroundColor: '#eff3f7', paddingTop: '50px', paddingBottom: '50px'}}>
                    <div className="container">
                        <div className="row mb-30">
                            <div className="col-md-6">
                                <div className="text-center">
                                    <h2>Latest News</h2>
                                    <LatestNews news={this.props.storage.news || []} />
                                    <button onClick={() => this.toNews()} className="btn btn-success icon-animated" style={{backgroundColor: 'rgb(92, 184, 92)'}}>
                                        <span>
                                            <i className="lynny-page-1"></i>
                                            See All News
                                        </span>
                                    </button>
                                    <hr className="news-separator" style={{margin:70+'px', border: '3px solid #eee'}}/>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="text-center">
                                    <h2>Upcoming Events</h2>
                                    <Events events={this.props.storage.events || []} />
                                    <Link to="/all/events" className="btn btn-success icon-animated" style={{backgroundColor: 'rgb(92, 184, 92)', marginBottom: 20+'px'}}>
                                        <span>
                                            <i className="lynny-page-1"></i>
                                            See All Events
                                        </span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        {/*<div className="row">
                            <div className="col-md-12">
                                <div className="custom-heading-02">
                                    <h2>Our Core Expertise</h2>
                                    <span>explore what we do</span>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-30">
                            <div className="col-md-4">
                                <div className="service-box service-box-03">
                                    <div className="icon-container">
                                        <i className="lynny-radar"></i>
                                    </div>

                                    <div className="text-container">
                                        <a href="management-services-single.html">
                                            <h3>Strategy & Growth</h3>
                                        </a>
                                        <p>
                                            Defining your business goals and steps to achieve them.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="service-box service-box-03">
                                    <div className="icon-container">
                                        <i className="lynny-globe-2_1"></i>
                                    </div>
                                    <div className="text-container">
                                        <a href="management-services-global-expansion.html">
                                            <h3>Global Expansion</h3>
                                        </a>

                                        <p>
                                            Helping you grow and defend against the global challenges.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="service-box service-box-03">
                                    <div className="icon-container">
                                        <i className="lynny-pages-1"></i>
                                    </div>
                                    <div className="text-container">
                                        <a href="management-services-audit.html">
                                            <h3>Audit & Assurance</h3>    
                                        </a>                            

                                        <p>
                                            Helping to enhance the value of your business.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="service-box service-box-03">
                                    <div className="icon-container">
                                        <i className="lynny-user-group-2"></i>
                                    </div>
                                    <div className="text-container">
                                        <a href="management-services-customer-strategy.html">
                                            <h3>Customer Strategy</h3>
                                        </a>
                                        <p>
                                            Achieve clear understanding of your customers needs.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="service-box service-box-03">
                                    <div className="icon-container">
                                        <i className="lynny-building-2"></i>
                                    </div>
                                    <div className="text-container">
                                        <a href="management-services-tax-consulting.html">
                                            <h3>Tax Consulting</h3>
                                        </a>
                                        <p>
                                            Understanding global taxes laws and practices.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="service-box service-box-03">
                                    <div className="icon-container">
                                        <i className="lynny-globes"></i>
                                    </div>
                                    <div className="text-container">
                                        <a href="management-services-subcategory.html">
                                            <h3>Mergers & Acquisitions</h3>
                                        </a>

                                        <p>
                                            We will help you expand your business.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>*/}
                    </div>
                </div>
                <div className="page-content custom-background bkg-color-white padding-small">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="call-to-action clearfix">
                                    <div className="text">
                                        <h4>For any help, take a look at our <Link to="/pages/faq">FAQ's</Link> or get in touch.</h4>
                                    </div>
                                    <Link to="/contact" className="btn btn-success icon-animated" style={{backgroundColor: 'rgb(92, 184, 92)'}}>
                                        <span>
                                            <i className="lynny-page-1"></i>
                                            Contact Us
                                        </span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = state => {
    return state;
}

const mapDispatchToProps = {
    resetMetaTags,
    setMetaData 
}
  
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(HomeComponent));