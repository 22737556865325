const defaultState = {
    tabs: [],
    currentTab: {},
    currentSubTab: {},
    currentBotTab: {}
}

export default function nav (state = defaultState, action) {
    switch(action.type) {
        case "ADD_TABS":
            return {
                ...state,
                tabs: action.payload.tabs
            }
        case "SET_CURRENT_TAB":
            return {
                ...state,
                currentTab: action.payload.currentTab,
                currentSubTab: action.payload.currentSubTab,
                currentBotTab: action.payload.currentBotTab
            }
        default:
            return state;
    }
}