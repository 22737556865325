import React from 'react';
import { connect } from 'react-redux';
import AccountOrdersComponent from './../../components/account/orders';

export let AccountOrders = (props) => {
    return <AccountOrdersComponent parent={props}/>
}

const mapStateToProps = state => {
    return state;
}
  
export default connect(
    mapStateToProps
)(AccountOrders);