import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { db, firebaseD } from '../../actions/settings';
import { convertDate, updateMetaTags } from '../../actions/';
import { AccountNav } from './nav';
import ClipLoader from "react-spinners/ClipLoader";

class AccountOrdersComponent extends React.Component {

    state = {
        orders: [],
        lastDoc: null,
        hasMore: false,
        loading: true
    }

    logOutSession = async () => {
        await firebaseD.auth().signOut();
        this.props.history.push("/");
        this.props.logout();
    }

    componentDidMount = async () => {
        this.props.updateMetaTags({
            title: 'My Orders - The British Tunnelling Society',
            description: "My Orders with the British Tunnelling Society.",
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: 'My Orders, British Tunnelling Society, BTS, Tunnelling, Tunnels, Mining, TBM'
                }
            }
        });
        try {
            let oDocs = await db   
                .collection("Orders")
                .where("customerId", "==", this.props.user.info.id)
                .orderBy("createdDate", "desc")
                .limit(5)
                .get();
            if (oDocs.size > 0) {
                let orders = [];
                oDocs.forEach((doc) => {
                    orders.push(doc.data());
                });
                this.setState({
                    orders,
                    lastDoc: oDocs.docs[oDocs.docs.length-1],
                    hasMore: (oDocs.size > 4) ? true : false,
                    loading: false
                });
            } else {
                this.setState({
                    loading: false,
                    hasMore: false
                });
            }
        } catch(e) {
            console.log(e);
            this.props.history.push("/account/orders");
        }
    }

    paginateOrders = async () => {
        this.setState({loading: true}, async () => {
            try {
                let oDocs = await db   
                    .collection("Orders")
                    .where("customerId", "==", this.props.user.info.id)
                    .orderBy("createdDate", "desc")
                    .startAfter(this.state.lastDoc)
                    .limit(5)
                    .get();
                if (oDocs.size > 0) {
                    let orders = this.state.orders;
                    oDocs.forEach((doc) => {
                        orders.push(doc.data());
                    });
                    this.setState({
                        orders: orders,
                        lastDoc: oDocs.docs[oDocs.docs.length-1],
                        hasMore: (oDocs.size > 4) ? true : false,
                        loading: false
                    });
                } else {
                    this.setState({
                        loading: false,
                        hasMore: false
                    });
                }
            } catch(e) {
                console.log(e);
            }
        });
    }

    render() {

        return (
            <>
                <div className="page-title page-title-style-01 bkg-img09">
                    <div className="pt-mask-light"></div>
                </div>
                <div className="page-content">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="custom-heading-01">
                                    <h2>My Orders</h2>
                                </div>
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Order #</th>
                                                <th>Date</th>
                                                <th>Items</th>
                                                <th>Total</th>
                                                <th>Paid</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.orders.length === 0 && !this.state.loading &&
                                            <tr><td colSpan="4">No previous orders.</td></tr>
                                            }
                                            {this.state.orders.map((order, i) => {
                                                return <tr key={i} className="orders-row">
                                                    <td><Link to={`/account/orders/${order.id}`}>{order.id}</Link></td>
                                                    <td><Link to={`/account/orders/${order.id}`}>{convertDate(order.createdDate.toDate())}</Link></td>
                                                    <td><Link to={`/account/orders/${order.id}`}>{order.items.length} Item(s)</Link></td>
                                                    <td><Link to={`/account/orders/${order.id}`}>£{order.total}</Link></td>     
                                                    <td><img style={{height:24+'px'}} src={(order.paid) ? "/img/tick.png" : "/img/cross.png"}/></td>                            
                                                </tr>
                                            })}
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td style={{paddingTop:20+'px'}} colSpan="5" className="no-top">
                                                    {this.state.loading &&
                                                    <ClipLoader
                                                        size={16}
                                                        color={"#5cb85c"}
                                                        loading={this.state.loading}
                                                    />
                                                    }
                                                    {!this.state.loading &&
                                                    <p>
                                                        <button 
                                                            onClick={this.paginateOrders}
                                                            className="btn btn-grey btn-small"
                                                            type="button"
                                                            disabled={!this.state.hasMore}
                                                        >
                                                            Load More
                                                        </button>
                                                    </p>
                                                    }
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                            <aside className="col-md-4 aside-right">
                                <ul className="aside-widgets">
                                    <li className="widget widget_nav_menu clearfix">
                                        <div className="menu-quick-links-container">
                                            <AccountNav comp={this}/>
                                        </div>
                                    </li>
                                </ul>
                            </aside>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = state => {
    return state;
}

const mapDispatchToProps = {
    updateMetaTags
}
  
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(AccountOrdersComponent));