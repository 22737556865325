import React from 'react';
import { connect } from 'react-redux';
import QuestionnairesComponent from './../../components/checkout/questions';

export let Questionnaires = (props) => {
    return <QuestionnairesComponent parent={props}/>
}

const mapStateToProps = state => {
    return state;
}
  
export default connect(
    mapStateToProps
)(Questionnaires);