import React from 'react';
import { connect } from 'react-redux';
import CheckoutCompleteComponent from './../../components/checkout/complete';

export let CheckoutComplete = (props) => {
    return <CheckoutCompleteComponent parent={props}/>
}

const mapStateToProps = state => {
    return state;
}
  
export default connect(
    mapStateToProps
)(CheckoutComplete);