import React from 'react';
import { connect } from 'react-redux';
import AccountOrderDetailsComponent from './../../components/account/orderDetails';

export let AccountOrderDetails = (props) => {
    return <AccountOrderDetailsComponent parent={props}/>
}

const mapStateToProps = state => {
    return state;
}
  
export default connect(
    mapStateToProps
)(AccountOrderDetails);