import React from 'react';
import { connect } from 'react-redux';
import HomeComponent from './../components/home';

export let Home = props => {
    return <HomeComponent props={props}/>
}

const mapStateToProps = state => {
    return state;
}
  
export default connect(
    mapStateToProps
)(Home);