import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { convertDate, emptyCart, updateMetaTags, validateForm, deleteInvoice, payByInvoice } from '../../actions/';
import { db, firebaseD } from '../../actions/settings';
import ClipLoader from "react-spinners/ClipLoader";
import { AddressForm } from './../checkout/address';

class AccountOrderEditDetailsComponent extends React.Component {

    state = {
        updating: false,
        orderNumber: this.props.match.params.orderid,
        order: {},
        purchaseOrderNumber: '',
        delivery: {
            firstname: '',
            lastname: '',
            address1: '',
            address2: '',
            city: '',
            postcode: '',
            county: '',
            country: 'United Kingdom',
            phone: ''
        },
        billing: {
            firstname: '',
            lastname: '',
            address1: '',
            address2: '',
            city: '',
            postcode: '',
            county: '',
            country: 'United Kingdom',
            phone: ''
        },
    }

    componentDidMount = async () => {
        this.props.updateMetaTags({
            title: 'Edit my order - The British Tunnelling Society',
            description: "Edit my order with the British Tunnelling Society.",
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: 'Edit Order, British Tunnelling Society, BTS, Tunnelling, Tunnels, Mining, TBM'
                }
            }
        });
        try {
            let doc = await db   
                .collection("Orders")
                .doc(this.state.orderNumber)
                .get();
            if (doc.exists && this.props.user.info.id === doc.data().customerId && !doc.data().paid) {
                let order = doc.data();
                this.setState({
                    order,
                    date: convertDate(doc.data().createdDate.toDate()),
                    delivery: order.delivery,
                    billing: order.billing,
                    purchaseOrderNumber: order.purchaseOrderNumber || ''
                });
            } else {
                this.props.history.push("/account/orders");
            }
        } catch(e) {
            console.log(e);
            this.props.history.push("/account/orders");
        }
    }

    setAddress = (type, key, val) => {
        var currentVal = {...this.state[type]};
        currentVal[key] = val;
        this.setState({[type]: currentVal});
    }

    saveAddress = () => {
        let { billing, delivery } = this.state;
        let checkArr = [
            ["d_firstname", delivery.firstname],
            ["d_lastname", delivery.lastname],
            ["d_address1", delivery.address1],
            ["d_city", delivery.city],
            ["d_postcode", delivery.postcode],
            ["d_country", delivery.country],
            ["b_firstname", billing.firstname],
            ["b_lastname", billing.lastname],
            ["b_address1", billing.address1],
            ["b_city", billing.city],
            ["b_postcode", billing.postcode],
            ["b_country", billing.country]
        ];
        return validateForm(checkArr); 
    }

    updateOrder = async () => {
        this.setState({updating: true}, async() => {
            let { delivery, billing, purchaseOrderNumber, orderNumber, order } = this.state;
            let error = this.saveAddress();
            if (!error) {
                try {
                    await db
                        .collection("Orders")
                        .doc(orderNumber)
                        .update({
                            
                        });
                    await deleteInvoice(order.invoice.id);
                    const data = {
                        id: this.props.user.info.id,
                        items: order.items,
                        orderid: orderNumber,
                        billing: billing,
                        po: purchaseOrderNumber
                    };
                    const invoice = await payByInvoice(data);
                    if (!invoice.id) throw new Error(invoice);
                    await db
                        .collection("Orders")
                        .doc(orderNumber)
                        .update({
                            delivery,
                            billing,
                            purchaseOrderNumber,
                            updatedDate: firebaseD.firestore.FieldValue.serverTimestamp(),
                            invoice: invoice,
                            delivery: delivery,
                            billing: billing
                        });
                    console.log("invoice", invoice)
                    this.props.history.push(`/account/orders/${orderNumber}?updated=true`)
                } catch(e) {
                    alert("Error updating order. Please try again.");
                }
            }
            this.setState({updating: false});
        });
    }

    render() {
        return (
            <>
                <div className="page-title page-title-style-01 bkg-img09">
                    <div className="pt-mask-light"></div>
                </div>
                <div className="page-content">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="message-boxes infobox-default" style={{marginBottom:50+'px'}}>
                                    <div className="icon-container">
                                        <i className="fa fa-info"></i>
                                    </div>
                                    <div className="notification-container" style={{paddingLeft:100+'px'}}>
                                        <p>You can make changes until your order has been paid.</p>
                                    </div>
                                </div>
                                <form className="wpcf7 wpcf7-contact-us clearfix">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <h4>Update Billing/Invoice Address</h4>
                                            <label>This will be updated on the new invoice.</label>
                                            {this.state.order.billing &&
                                            <AddressForm 
                                                prefix="b"
                                                type="billing"
                                                data={this.state.billing}
                                                setAddress={this.setAddress}
                                            />
                                            }
                                            <h4>Update Delivery Address</h4>
                                            {this.state.order.delivery &&
                                            <AddressForm 
                                                prefix="d"
                                                type="delivery"
                                                data={this.state.delivery}
                                                setAddress={this.setAddress}
                                            />
                                            }
                                        </div>
                                        <div className="col-md-6">
                                            <h4>Your Invoice</h4>
                                            <div className="form-group">
                                                <label>Purchase Order Number</label>
                                                <input 
                                                    onChange={(e) => this.setState({purchaseOrderNumber: e.target.value})} 
                                                    value={this.state.purchaseOrderNumber} 
                                                    type="text" 
                                                    className="wpcf7-textarea" 
                                                    id="purchaseOrderNumber"
                                                    placeholder="Add a Purchase Order Number"
                                                />
                                            </div>
                                            <div className="row">
                                                {!this.state.updating && 
                                                <div className="col-md-12">
                                                    <div className="pull-left">
                                                        <button 
                                                            className="btn btn-grey"
                                                            type="button"
                                                            onClick={() => this.props.history("/account/orders")}
                                                        >
                                                            Back to Orders
                                                        </button>
                                                    </div>
                                                    <div className="pull-right">
                                                        <button 
                                                            className="btn btn-success marg-right-20"
                                                            type="button"
                                                            onClick={this.updateOrder}
                                                        >
                                                            Update Invoice
                                                        </button>
                                                    </div>
                                                </div>
                                                }
                                                {this.state.updating && 
                                                <div className="col-md-12">
                                                    <div className="pull-left">
                                                        <span>Updating Invoice... {` `}</span>
                                                        <ClipLoader
                                                            size={16}
                                                            color={"#5cb85c"}
                                                            loading={this.state.updating}
                                                        />
                                                    </div>
                                                </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = state => {
    return state;
}

const mapDispatchToProps = {
    emptyCart,
    updateMetaTags
}
  
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(AccountOrderEditDetailsComponent));