import React from 'react';
import { Link } from 'react-router-dom';

export const AccountNav = ({comp}) => {
    let path = comp.props.location.pathname;
    return (
        <ul id="menu-quick-links" className="menu">
            <li className={setCurrent("/account/details", path)}>
                <Link to="/account/details">My Details</Link>
            </li>
            <li className={setCurrent("/account/orders", path)}>
                <Link to="/account/orders">Orders</Link>
            </li>
            <li className="menu-item">
                <a href="#" onClick={() => comp.logOutSession()}>Logout</a>
            </li>
        </ul>
    )
}

function setCurrent(path, currentPath) {
    if (path === currentPath) {
        return "menu-item current-menu-item";
    } else {
        return "menu-item";
    }
}