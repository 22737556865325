import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { updateMetaTags } from './../../actions/';
import { db } from './../../actions/settings';
import { ContentSwitch } from './switch';
import { ContentSkeleton } from './../../skeletons/content';

class ShowAll extends React.Component {

    state = {
        items: [],
        loading: true,
        type: ''
    }

    componentDidMount = async () => {
        let type = '';
        if (this.props.match.path === "/all/events") {
            type = "event"
        } else if (this.props.match.path === "/all/news") {
            type = "news"
        }
        let items = [];
        this.setState({type}, async() => {
            let docs = await db
                .collection('Pages')
                .where('deleted', '==', false)
                .where('active', '==', true)
                .where(type, '==', true)
                .orderBy('eventDate', 'desc')
                .get();
            if (docs.size > 0) {
                
                docs.forEach(doc => {
                    items.push(doc.data());
                });
            }
            this.setState({
                loading: false,
                items: items
            });
        });
        this.props.updateMetaTags({
            title: 'All - The British Tunnelling Society',
            description: "See all the _ British Tunnelling Society by sending us a message.",
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: 'Events, News BTS, British Tunnelling Society, BTS, Tunnelling, Tunnels, Mining, TBM'
                }
            }
        });
    }

    render() {
        
        return (
            <>
                <div className="page-title page-title-style-01 bkg-img09">
                    <div className="pt-mask-light"></div>
                </div>
                <div className="page-content">
                    <div className="container">
                        <div className="row">
                        {this.state.loading && <ContentSkeleton />}
                        {this.state.items.map((item, i) => {
                            return <React.Fragment key={item.id}>
                                <ContentSwitch
                                    template={item.template}
                                    data={item}
                                    showingAll={true}
                                />
                                <hr/>
                            </React.Fragment>
                        })}
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapDispatchToProps = {
    updateMetaTags
}

const mapStateToProps = state => {
    return state;
}
  
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(ShowAll));