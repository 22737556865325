import React from 'react';
import { connect } from 'react-redux';
import { cleanHtml } from '../../actions';
import Navlist from '../navigation/Navlist';

export const FlexiblePage = ({data, showingAll}) => {
  let subItems = data.subItems;

  let subItemData = [];
  if (subItems.length > 0) {
    subItems.forEach(
      item => subItemData.push(item),
    );
  }
  let cols = "8";
  return (
    <div className="container">
      <div className="row">
        <div className={`col-md-${cols}`}>
          <div className="row">
            <div className="col-md-12">
                <div className="custom-heading-01">
                    <h2>{data.title}</h2>
                </div>
                {subItemData.map((item, i) => {
                    let key = Object.keys(item)[0];
                    if (key === "image") {
                        return <div key={"image"+i} className="col-md-3">
                        <img src={item[key]} />
                      </div>
                    } else if (key === "content") {
                        return <div key={"content"+i} className="col-md-12" style={{marginTop: '20px'}}>
                            <p dangerouslySetInnerHTML={{ __html: item[key] }}></p>
                        </div>
                    } else if (key === "download") {
                        let downloads = [];
                        item[key].map((download, x) => {
                            downloads.push(<li key={x} className="post-container clearfix">
                                {download.imageUrl &&
                                  <div className="post-media">
                                    <img src={download.imageUrl} alt={download.title} />
                                  </div>
                                }
                                {download.subItemUrl && 
                                <>
                                <p>{download.title}</p>
                                <div className="post-media">
                                  <a
                                  href={download.subItemUrl}
                                  download={download.subItemUrl}
                                  target="_blank"
                                  className="btn btn-success btn-block"
                                  role="button"
                                  style={{ backgroundColor: '#5cb85c' }}
                                  >
                                  Download
                                  </a>
                                </div>
                                </>
                                }
                                
                            </li>)
                        });
                        return <ul key={i} className="col-md-12 blog-posts blog-list clearfix">{downloads}</ul>
                    } else if (key === "meeting") {
                      let meetings = item[key].map((meeting, m) => {
                        let date = meeting.date;
                        if (meeting.date) {
                          let dArr = meeting.date.split("-");
                          if (dArr.length === 3) {
                            date = dArr[2]+"/"+dArr[1]+"/"+dArr[0];
                          }
                        }
                        return <>
                        <div className="row" key={m}>
                          <div className="col-md-2" style={{marginTop: '20px'}}>
                            {meeting.date && 
                            <div className="row">
                              <div className="col-md-12">
                                <p>{date}</p>
                              </div>
                            </div>
                            }
                            {meeting.image && 
                            <div className="row">
                              <div className="col-md-12">
                                <div className="post-media">
                                  <img src={meeting.image}/>
                                </div>
                              </div>
                            </div>
                            }
                          </div>
                          <div className="col-md-8" style={{marginTop: '20px'}}>
                            <h3 style={{marginBottom:'10px'}}>{meeting.title}</h3>
                            <p dangerouslySetInnerHTML={{ __html: meeting.text || '' }}></p>
                            {meeting.speakers &&
                            <>
                              <p style={{paddingBottom:'10px'}}><b>Speaker(s)</b></p>
                              <p>{meeting.speakers}</p>
                            </>
                            }
                          </div>
                          <div className="col-md-2" style={{marginTop: '20px'}}>
                            {meeting.invite &&
                            <div className="row" style={{marginBottom: '2px'}} className="inline-mobile">
                              <div className="col-md-12">
                                <div className="post-media">
                                  <a href={meeting.invite} download>
                                    <img src="/img/calendar.png" className="meeting-link"/>
                                  </a>
                                </div>
                              </div>
                            </div>
                            }
                            {meeting.flyer &&
                            <div className="row" style={{marginBottom: '2px'}} className="inline-mobile">
                              <div className="col-md-12">
                                <div className="post-media">
                                  <a href={meeting.flyer} download>
                                    <img src="/img/download.png" className="meeting-link"/>
                                  </a>
                                </div>
                              </div>
                            </div>
                            }
                            {meeting.video &&
                            <div className="row" style={{marginBottom: '2px'}} className="inline-mobile">
                              <div className="col-md-12">
                                <div className="post-media">
                                  <a href={meeting.video} target="_blank">
                                    <img src="/img/video.png" className="meeting-link"/>
                                  </a>
                                </div>
                              </div>
                            </div>
                            }
                          </div>
                        </div>
                        <hr/>
                        </>
                      });
                      return meetings; 
                    } else if (key === "member") {
                      let members = item[key].sort(function(a, b) {
                          return parseFloat(a.displayOrder || 0) - parseFloat(b.displayOrder || 0);
                        }).map((member, i) => {
                        return <>
                        <div className="row" key={i}>
                          <div className="col-sm-4" style={{marginTop: '20px'}}>
                            {member.image && 
                            <div className="row">
                              <div className="col-md-12">
                                <div className="post-media">
                                  <img src={member.image}/>
                                </div>
                              </div>
                            </div>
                            }
                          </div>
                          <div className="col-sm-5" style={{marginTop: '20px'}}>
                            <h3 style={{marginBottom:'10px'}}>{member.company}</h3>
                            <p className="corporate-member-p" dangerouslySetInnerHTML={{ __html: member.address || '' }}></p>
                          </div>
                          <div className="col-sm-3" style={{marginTop: '20px'}}>
                            {member.representatives &&
                            <>
                              <h5 style={{marginBottom:'10px'}}>Representatives</h5>
                              <p className="corporate-member-p" dangerouslySetInnerHTML={{ __html: member.representatives || '' }}></p>
                              </>
                            }
                          </div>
                        </div>
                        <hr/>
                        </>
                      });
                      return members; 
                    }
                })}
              </div>
            </div>
          </div>
          {!showingAll && 
        <aside className="col-md-4 aside-right">
          <ul className="aside-widgets">
            <li className="widget widget_nav_menu clearfix">
              <Navlist pageId={data.id} />
            </li>
          </ul>
        </aside>
        }
        </div>
        
      </div>
  );
}
