import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactFancyBox from 'react-fancybox';
import ClipLoader from "react-spinners/ClipLoader";
import { db } from './../../actions/settings';
import { ToastsStore } from 'react-toasts';
import { addToCart, createOrder, updateCart, updateMetaTags, addQuestionnaires } from './../../actions/';

class ProductDetails extends React.Component {

    state = {
        loading: true,
        link: this.props.match.params.link,
        data: {},
        cart: this.props.cart,
        adding: false,
        variants: []
    }

    componentDidMount = async () => {
        let link = this.state.link;
        if (link) {
            let pDoc = await db
                .collection("Products")
                .where("link", "==", link)
                .limit(1)
                .get();
            if (pDoc.size > 0) {
                pDoc.forEach((doc) => {
                    if (!doc.data().active) this.props.history.push("/404");
                    this.setState({
                        data: doc.data(),
                        loading: false,
                        variants: doc.data().variants
                    });
                    this.props.updateMetaTags({
                        title: doc.data().title + ' - The British Tunnelling Society',
                        description: doc.data().shortDescription,
                        meta: {
                            charset: 'utf-8',
                            name: {
                                keywords: doc.data().title + ',British Tunnelling Society, BTS, Tunnelling, Tunnels, Mining, TBM'
                            }
                        }
                    });
                });
            } else {
                this.props.history.push("/404");
            }
        } else {
            this.props.history.push("/404");
        }
    }

    setQuantity = (index, val) => {
        let useVal = (isNaN(val) || val < 0) ? 1 : val;
        let newVariants = this.state.variants;
        newVariants[index].quantity = useVal;
        this.setState({variants: newVariants});
    }

    createOrderNumber = async () => {
        let ref = db
            .collection("Settings")
            .doc("Numbers");
        return db.runTransaction(async function(transaction) {
            let nDoc = await transaction.get(ref);
            let newNumber = nDoc.data().orderNumber + 1;
            transaction.update(ref, { orderNumber: newNumber });
            return newNumber;
        });
    }

    buyProduct = async (variant) => {
        variant.parentId = this.state.data.id;
        this.setState({adding: true});
        if (this.props.cart.products.length === 0) {
            let number = await this.createOrderNumber();
            this.props.createOrder(`ORD${number.toString().padStart(6, '0')}`);
        }

        let current = this.props.cart.products;
        let exists = false, newProducts;
        current.map(p => {
            if (p.variantId === variant.variantId) {
                let qty = Number(p.quantity) + Number(variant.quantity);
                p.quantity = qty;
                variant.orderItemId = p.orderItemId;
                exists = true;
            }
        });
        if (exists) {
            newProducts = current;
        } else {
            let randomstring = require("randomstring");
            let orderItemId = randomstring.generate(8);
            variant.orderItemId = orderItemId;
            newProducts = [...current, variant]
        }
        variant.questionnaires.map((qs, x) => {
            variant.questionnaires[x].productId = variant.parentId;
            variant.questionnaires[x].orderNumber = this.props.cart.order.number;
        });
        await this.props.updateCart(newProducts);
        await this.props.addQuestionnaires(variant);
        ToastsStore.success("Added to basket.");
        this.setState({variants: this.state.data.variants, adding:false});
    }

    render() {
        let { data, adding, variants } = this.state;
        return (
            <>
                <div className="page-title page-title-style-01 bkg-img09">
                    <div className="pt-mask-light"></div>
                </div>
                <div className="page-content">
                    <div className="container">
                        {this.state.data.title &&
                        <div className="row">
                            <div className="col-md-12">
                                <h2>{data.title}</h2>
                                <div>
                                    <div className="row">
                                        <div className="col-md-4">
                                            {data.image &&
                                            <ReactFancyBox
                                                image={data.image}
                                            />
                                            }
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div>
                                                <p>{data.shortDescription}</p>
                                                <p dangerouslySetInnerHTML={{ __html: data.longDescription }}></p>
                                            </div>
                                            <div className="custom-heading-03">
                                                <h5>Options for {data.title}</h5>
                                            </div>
                                            <ul className="fa-ul large-icons detailed clearfix">
                                                {variants.map((variant, i) => {
                                                    return <li key={i}>
                                                        <div className="li-content">
                                                            <h4>{variant.title}</h4>
                                                            <p>{variant.description}</p>
                                                            <p style={{fontWeight:'bold'}}>£{(variant.priceTotal).toFixed(2)}</p>
                                                            {!adding && variant.overallStock > 0 &&
                                                            <button 
                                                                type="button" 
                                                                className="btn btn-small" 
                                                                style={{backgroundColor: "rgb(92, 184, 92)"}}
                                                                onClick={() => this.buyProduct(variant)}
                                                            >
                                                                <span>Buy Now</span>
                                                            </button>
                                                            }
                                                            {!adding && variant.overallStock < 1 &&
                                                            <button 
                                                                type="button" 
                                                                className="btn btn-small btn-grey" 
                                                                disabled={true}
                                                            >
                                                                <span>Out of Stock</span>
                                                            </button>
                                                            }
                                                            {adding &&
                                                            <button 
                                                                type="button" 
                                                                className="btn btn-small" 
                                                                style={{backgroundColor: "rgb(92, 184, 92)"}}
                                                            >
                                                                <ClipLoader
                                                                    size={16}
                                                                    color={"#ffffff"}
                                                                    loading={adding}
                                                                />
                                                            </button>
                                                            }  
                                                            <input 
                                                                className="qty-input"
                                                                type="number" 
                                                                value={variant.quantity}
                                                                onChange={(e) => this.setQuantity(i, e.target.value)}
                                                            />
                                                        </div>
                                                    </li>
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = state => {
    return state;
};

const mapDispatchToProps = {
    addToCart,
    createOrder,
    updateCart,
    updateMetaTags,
    addQuestionnaires
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductDetails));
  