import React from 'react';
import { connect } from 'react-redux';
import { cleanHtml } from '../../actions';
import Navlist from '../navigation/Navlist';

export const DownloadPage = ({data, showingAll}) => {
  let subItems = data.subItems;

  let subItemData = [];
  if (subItems.length > 0) {
    subItems.forEach(
      item => subItemData.push(item),
    );
  }
  let title = data.title;
  let img = data.items.find(item => item.image);
  let morecontent = data.items.find(item => item.morecontent);
  if (morecontent) {
    morecontent = cleanHtml(morecontent.morecontent);
  }
  let image = (img) ? img['image'] : null;
  let content = cleanHtml(data.content);
  let cls = (image && (image.constructor === Object && Object.keys(image).length > 0)) 
    ? 'col-md-9' 
    : 'col-md-12';
  let cols = (showingAll) ? "12" : "8";
  let imageItem = data.items.filter(i => i.image);
  console.log("imageItem", imageItem)
  return (
    <div className="container">
      <div className="row">
        <div className={`col-md-${cols}`}>
          <div className="row">
            <div className="col-md-12">
              <div className="custom-heading-01">
                <h2>{title}</h2>
                {data.speakers && 
                <p>{<b>Speakers:</b>}{` `}{data.speakers}</p>
                }
                <div style={{marginBottom: 30+'px'}}>
                  {data.youtube && 
                  <a 
                      className="btn btn-success icon-animated" 
                      href={data.youtube}
                      target="_blank"
                      style={{backgroundColor: 'rgb(92, 184, 92)', marginRight: 20+'px'}}>
                      <span style={{color: 'white', marginBottom: 0}}>
                          <i className="lynny-tv"></i>Video
                      </span>
                  </a>
                  }
                  {data.flyer && 
                  <a 
                      className="btn btn-success icon-animated" 
                      href={data.flyer}
                      target="_blank"
                      style={{backgroundColor: 'rgb(92, 184, 92)', marginRight: 20+'px'}}>
                      <span style={{color: 'white', marginBottom: 0}}>
                          <i className="lynny-download"></i>Download Flyer
                      </span>
                  </a>
                  }
                </div>
              </div>
              {image && image.constructor === Object && Object.keys(image).length > 0 &&
              <div className="col-md-3">
                <img src={image} />
              </div>
              }
              {imageItem.length > 0 &&
              <div className="col-md-3">
                <img src={imageItem[0].image} />
              </div>
              }
              <div className={cls}>
                <p dangerouslySetInnerHTML={{ __html: content }}></p>
              </div>
            </div>
          </div>
          <ul className="col-md-12 blog-posts blog-list clearfix">
            {subItemData.map((item, i) => {
              let paddingLeft = (item.imageUrl) ? '' : 0+'px';
              return (
                <li key={i} className="post-container clearfix">
                  <div className="post-body" style={{paddingLeft: paddingLeft, clear: 'both', paddingTop: '20px'}}>
                    <h3>{item.title}</h3>
                    <p>{item.text}</p>
                    
                  </div>
                  {item.imageUrl &&
                  <div className="post-media">
                     {item.imageUrl &&
                      <img src={item.imageUrl} alt={item.title} />
                     }
                    
                  </div>
                  }
                  {item.subItemUrl && 
                  <div className="post-media" style={{clear:'both'}}>
                    <a
                      href={item.subItemUrl}
                      download={item.subItemUrl}
                      target="_blank"
                      className="btn btn-success btn-block"
                      role="button"
                      style={{ backgroundColor: '#5cb85c' }}
                    >
                      Download
                    </a>
                  </div>
                  }
                  
                </li>
              );
            })}
          </ul>
          {morecontent && 
          <div className="row">
            <div className="col-md-12">
              <div className={cls}>
                <p dangerouslySetInnerHTML={{ __html: morecontent }}></p>
              </div>
            </div>
          </div>
          }
          <hr />
        </div>
        {!showingAll && 
        <aside className="col-md-4 aside-right">
          <ul className="aside-widgets">
            <li className="widget widget_nav_menu clearfix">
              <Navlist pageId={data.id} />
            </li>
          </ul>
        </aside>
        }
      </div>
    </div>
  );
}
